import hasAuthority from "@common/hasAuthority";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavItemDesktop from "@components/Navigation/Desktop/NavLinkDesktop";
import Nav from "react-bootstrap/Nav";
import React, {useMemo} from "react";
import {NavigationObjectItem} from "@components/Navigation/getNavigationObject";
import activeRoleState from "@state/globalState/activeRoleState";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import RoleGroupRoles from "@interfaces/RoleGroupRoles";
import {createGlobalState} from "react-global-hooks";
import Dropdown from "react-bootstrap/Dropdown";

export const navDropDownState = createGlobalState<Array<string>>([]);
export const maxDropdownHeightState = createGlobalState<number>(0);

const onOpenHandler = (nav: NavigationObjectItem, isOpen: boolean, event: React.SyntheticEvent<Dropdown>) => {
  if (nav.id) {
    if (isOpen) {
      navDropDownState.set([...navDropDownState.get(), nav.id]);
      window.scrollTo(0, 0);
      setTimeout(() => {
        const dropDownElement = (event.target as HTMLElement).nextSibling as HTMLElement;
        const allSubMenuLinkElements = dropDownElement.getElementsByClassName('subMenuLink');

        const maxSubMenuHeight = Array.from(allSubMenuLinkElements).reduce((acc, element) => {
          const totalElementsHeight = element.querySelectorAll('a').length * 48;
          return acc < totalElementsHeight ? totalElementsHeight : acc;
        }, 0);
        maxDropdownHeightState.set(maxSubMenuHeight + dropDownElement?.offsetHeight);
      })
    } else {
      navDropDownState.set(navDropDownState.get().filter(id => id !== nav.id));
    }
  }
}

const recursiveReduce = (nav: NavigationObjectItem[]) => {
  return nav.reduce((count, currentNav) => {
    count += (currentNav.countBadge || 0);
    if (currentNav.children && currentNav.children.length > 0) {
      count += recursiveReduce(currentNav.children);
    }
    return count;
  }, 0);
}

export const DropdownNavigationTitle = ({nav}: { nav: NavigationObjectItem }) => {
  const totalCount = useMemo(() => {
    return recursiveReduce(nav.children || [])
  }, [nav.children])

  if (!totalCount) return <>{nav.text}</>;

  return (
    <>
      {nav.text}
      <span
        className={`badge badge-danger p-0`}
        style={{}}
      >{totalCount}</span>
    </>
  );
}

export const roleBasedChildrenRenderer = (nav: NavigationObjectItem, activeRole: number | null, userRoleGroups: RoleGroupRoles[] | null) => {
  return nav.children?.map((subNav, sIndex) => {
    if (subNav.hide) {
      return null;
    } else if (!activeRole && subNav.roles) {
      return null;
    } else if (activeRole && subNav.excludeRoles && (hasAuthority(subNav.excludeRoles, userRoleGroups, activeRole))) {
      return null;
    } else if (subNav.roles && activeRole && !(hasAuthority(subNav.roles, userRoleGroups, activeRole))) {
      return null;
    } else if (subNav?.divider) {
      return (
        <div key={sIndex} className="dropdown-divider"/>
      )
    } else {
      return <NavItemDesktop nav={subNav} key={sIndex} Component={NavDropdown.Item}/>
    }
  }).filter(x => x)
}

const NavigationMenuRenderer = ({nav, alignRight = false}: { nav: NavigationObjectItem, alignRight?: boolean }) => {
  const activeRole = activeRoleState.useValue();
  const userRoleGroups = userRoleGroupsState.useValue();

  const roleBasedChildren = useMemo(() => {
    return roleBasedChildrenRenderer(nav, activeRole, userRoleGroups)
  }, [nav, userRoleGroups, activeRole]);

  if (
    nav.hide ||
    (nav.children?.length && !roleBasedChildren?.length) ||
    (!activeRole && nav.roles) ||
    (activeRole && nav.excludeRoles && hasAuthority(nav.excludeRoles, userRoleGroups, activeRole)) ||
    (nav.roles && activeRole && !hasAuthority(nav.roles, userRoleGroups, activeRole))
  ) {
    return null;
  } else if (roleBasedChildren?.length && nav.id) {
    return (
      <NavDropdown onToggle={(isOpen, event) => {
        onOpenHandler(nav, isOpen, event);
      }} alignRight={alignRight} title={<DropdownNavigationTitle nav={nav}/>} id={nav.id}>
        {roleBasedChildren}
      </NavDropdown>
    )
  } else {
    return (
      <li className="nav-item">
        <NavItemDesktop nav={nav} Component={Nav.Link}/>
      </li>
    )
  }
}

export default NavigationMenuRenderer