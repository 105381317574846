//https://secure.hornet.capital/register?invite=dGVzdGluZ2VtYWlsQHRlc3QuY29t

export const getQueryEmail = (email: string) => {
  try {
    return Buffer.from(email, 'base64').toString('utf-8')
  } catch (e) {
    return '';
  }
}

export const getQueryRoles = (roles: string) => roles.split('-').filter(x => x)
