import React, {ForwardedRef, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap';
import style from './style.module.scss';
import classNames from 'classnames';
import {statesTitleCase as statesObject} from '@common/states';
import DatePicker, {ReactDatePicker} from 'react-datepicker';
import {FaExclamation, FaPlus, FaTimes} from 'react-icons/fa';

import 'react-quill/dist/quill.snow.css';
import './react-quill-group.scss';
import './searchable-select.scss';
import './form-fields.scss';

import {
  AddressProps,
  CheckboxGroupProps,
  CheckboxProps,
  DateProps,
  HiddenProps,
  QuillEditorFieldProps,
  RadiosProps,
  SearchableSelectCommonProps,
  SelectProps,
  TextProps,
  ViewOptionProps,
} from './interfaces';

import {getErrorMessage} from './utils';

import ContactSelectorComponent from './ContactSelector';
import SmartInputComponent from './SmartInput';
import {usePlacesWidget} from 'react-google-autocomplete';
import configurationState from '@state/globalState/configurationState';
import SelectControl from "@components/forms/react-hook-form-bootstrap/SelectControl";
import ReactQuill from "react-quill";
import {CHECKBOX_GROUP_PREFIX} from "@components/forms/react-hook-form-bootstrap/constants";
import {YesNoEnum} from "@interfaces/Enums";
import moment from "moment";
import {DateFormats} from "@interfaces/DateFormats";
import BaseSelectBoxWithRef from "@components/forms/BaseSelectBoxWithRef";
import {Controller} from "@components/forms/react-hook-form-bootstrap/Controller";
import {hasValidValue, stringToDate} from "@common/basic";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";

export {FileUploadControl} from './FileUploadControl';

export const ContactSelector = ContactSelectorComponent;

export const SmartInput = SmartInputComponent;

const {Quill} = ReactQuill;

const Link = Quill.import('formats/link');

class CustomLink extends Link {
  static create(value: any) {
    const node = super.create(value);
    if (value.startsWith(window.location.origin)) {
      node.removeAttribute('target');
    }
    return node;
  }
}

Quill.register(CustomLink, true);

export const Hidden = (
  {
    name,
    rules,
    shouldUnregister,
    defaultValue = '',
    control,
    onChange,
    disabled = false,
    getDefaultFunction,
  }: HiddenProps
) => {
  const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={dv}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({field}) => {
        return (
          <Form.Group
            id={`${name}-scrollTo`}
            style={{display: 'none'}}
            className={classNames(style.formInput, 'mb-3')}
            controlId={`form-${field.name}`}
          >
            <div id={`${name}-scrollTo`}/>
            <Form.Control
              type={'hidden'}
              {...field}
              disabled={disabled}
              onChange={(event) => {
                if (onChange) {
                  onChange(event.target.value);
                }
                field.onChange(event);
              }}
            />
          </Form.Group>
        );
      }}
    />
  );
}

const FieldDescription = ({desc}: {
  desc: ReactNode
}) => (desc ? <small className={'description'}>{desc}</small> : null)

export const TextBox = React.forwardRef(
  (
    {
      name,
      id,
      showOnly,
      rules,
      label,
      type = 'text',
      maxLength,
      step,
      shouldUnregister,
      defaultValue = '',
      control,
      leftElement,
      as,
      rows,
      onChange,
      filterValueBeforeChange,
      disabled = false,
      getDefaultFunction,
      renderOnlyControl,
      placeholder,
      description,
      horizontalLayout,
      disableDigitMarker
    }: TextProps,
    ref
  ) => {
    const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={dv}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({
                   field,
                   // fieldState: { invalid, isTouched, isDirty, error },
                   fieldState,
                   // formState,
                 }) => {
          const isRequired = Boolean(rules?.required);
          const isInvalid = Boolean(fieldState.error);
          const hasValue = useMemo(() => hasValidValue(field.value), [field.value])

          const [isFocus, setIsFocus] = useState<boolean>(false);
          const [showPassword, setShowPassword] = useState<boolean>(false);
          const classesName = useMemo(() => getFieldClasses(field.value, type == "number" && disableDigitMarker), [field.value, disableDigitMarker]);
          const labelClassesName = useMemo(() => getLabelClasses(field.value, disabled), [field.value, disabled]);
          const updatedType = useMemo(() => type == "password" && showPassword ? "text" : type, [type, showPassword]);

          const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const updateEvent = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
              return {...event, target: {...event.target, value}}
            }

            if (maxLength) {
              event = updateEvent(event, event.target.value.substring(0, maxLength));
            }
            if (filterValueBeforeChange) {
              event = updateEvent(event, filterValueBeforeChange(event.target.value))
            }
            if (onChange) {
              onChange(event.target.value);
            }
            field.onChange(event);
          }
          if (renderOnlyControl) {
            return (
              showOnly ?
                <div className={`input-group-append ${style.showOnlyValue}`}>{field.value}</div>
                : (
                  <Form.Control
                    id={id}
                    required={isRequired}
                    type={updatedType}
                    className={'input-group-append'}
                    {...field}
                    step={step}
                    as={as}
                    rows={rows}
                    disabled={disabled}
                    ref={ref}
                    onChange={onChangeHandler}
                    // isValid={fieldState.isTouched && !isInvalid}
                    isInvalid={isInvalid}
                    placeholder={placeholder}
                  />
                )
            )
          }
          return (
            <Form.Group
              as={Row}
              className={classNames(style.formInput, 'mb-3 floating-form-group')}
              controlId={`form-${field.name}`}
            >
              <div id={`${name}-scrollTo`}/>
              {
                showOnly && <Form.Label className={'show-only'} column sm={horizontalLayout ? 4 : 12}>
                      <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                  </Form.Label>
              }
              <Col sm={horizontalLayout ? 8 : 12}>

                {
                  showOnly ?
                    <InputGroup>
                      <div className={style.showOnlyValue}>{leftElement}{field.value}</div>
                    </InputGroup>
                    :
                    <InputGroup
                      className={classNames(isInvalid ? 'is-invalid' : '', leftElement ? 'with-pre-group' : '', description ? 'pb-0' : '')}>
                      {leftElement}
                      <Form.Control
                        required={isRequired}
                        type={updatedType}
                        {...field}
                        step={step}
                        as={as}
                        rows={rows}
                        disabled={disabled}
                        ref={ref}
                        onChange={onChangeHandler}
                        className={classesName}
                        // isValid={fieldState.isTouched && !isInvalid}
                        isInvalid={isInvalid}
                        placeholder={placeholder}
                        onBlur={() => {
                          setTimeout(() => {
                            setIsFocus(false);
                          }, 200);
                        }}
                        onFocus={() => {
                          setIsFocus(true);
                        }}
                      />
                      <Form.Label className={labelClassesName}>
                        <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                      </Form.Label>
                      <CleanValue isInvalid={isInvalid} disabled={disabled} isFocusedAndHasValue={isFocus && hasValue}
                                  clear={() => field.onChange({target: {value: ''}})}/>
                      {
                        type == "password" && (
                          showPassword ?
                            <FontAwesomeIcon
                              onClick={() => {
                                setShowPassword(false);
                              }}
                              icon="fa-solid fa-eye-slash"
                              className={'password-eye'}
                            />
                            : <FontAwesomeIcon
                              onClick={() => {
                                setShowPassword(true);
                              }}
                              icon="fa-solid fa-eye"
                              className={'password-eye'}
                            />)

                      }
                    </InputGroup>
                }
                <FieldDescription desc={description}/>
                {fieldState.error ? (
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessage(fieldState.error)}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            </Form.Group>
          );
        }}
      />
    )
  }
);
export const Radios = (
  {
    name,
    showOnly,
    rules,
    label,
    shouldUnregister,
    defaultValue = '',
    control,
    options,
    onChange,
    disabled = false,
    getDefaultFunction,
    disabledKeys = []
  }: RadiosProps
) => {
  const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={dv}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({field, fieldState}) => {
        const isRequired = Boolean(rules?.required);
        const isInvalid = Boolean(fieldState.error);
        return (
          <Form.Group
            className={classNames(style.formInput, 'mb-3')}
            controlId={`form-${field.name}`}
          >
            <div id={`${name}-scrollTo`}/>
            {
              label && <Form.Label>
              <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
              {isRequired && !showOnly ? (
                <small className="text-warning" style={{marginLeft: 4}}>
                  (Required)
                </small>
              ) : null}
            </Form.Label>
            }
            {
              showOnly ?
                <div className={style.showOnlyValue}>{options[field.value]}</div>
                :
                Object.keys(options).map((key, index) => {
                  return (
                    <Form.Check
                      type={'radio'}
                      label={options[key]}
                      key={index}
                      onChange={(event) => {
                        if (onChange) {
                          onChange(event.target.value);
                        }
                        field.onChange(event);
                      }}
                      value={key}
                      checked={key === field.value}
                      isInvalid={isInvalid}
                      id={`form-${field.name}-${index}`}
                      name={field.name}
                      disabled={disabled || disabledKeys.includes(key)}
                    />
                  );
                })
            }
            {fieldState.error ? (
              <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                {getErrorMessage(fieldState.error)}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        );
      }}
    />
  );
};

export const Select = (
  {
    id,
    name,
    showOnly,
    showEmptyOption = true,
    emptyOption,
    rules,
    label,
    shouldUnregister,
    defaultValue = '',
    control,
    options,
    onChange,
    disabled = false,
    onAdd,
    getDefaultFunction,
    rightElement,
    renderOnlyControl = false,
    description,
    horizontalLayout,
    hideAppearanceWhenDisabled,
  }: SelectProps
) => {
  const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={dv}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({field, fieldState}) => {
        const isRequired = Boolean(rules?.required);
        const isInvalid = Boolean(fieldState.error);

        if (renderOnlyControl) {
          return (
            <SelectControl
              id={id}
              showOnly={showOnly}
              emptyOption={emptyOption}
              showEmptyOption={showEmptyOption}
              isRequired={isRequired}
              field={field}
              onChange={onChange}
              isInvalid={isInvalid}
              disabled={disabled}
              options={options}
            />
          );
        }
        const hasValue = useMemo(() => hasValidValue(field.value), [field.value])
        const [isFocus, setIsFocus] = useState<boolean>(false);
        const labelClassesName = useMemo(() => getLabelClasses(field.value, disabled), [field.value, disabled]);
        return (
          <Form.Group
            as={Row}
            className={classNames(style.formInput, 'mb-3 floating-form-group')}
            controlId={`form-${field.name}`}
          >
            <div id={`${name}-scrollTo`}/>
            {
              showOnly && <Form.Label className={'show-only'} column sm={horizontalLayout ? 4 : 12}>
                    <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                </Form.Label>
            }
            <Col sm={horizontalLayout ? 8 : 12}>
              <InputGroup className={classNames(isInvalid ? 'is-invalid' : '', description ? 'pb-0' : '')}>
                <SelectControl
                  id={id}
                  showOnly={showOnly}
                  emptyOption={emptyOption}
                  showEmptyOption={showEmptyOption}
                  isRequired={isRequired}
                  field={field}
                  onChange={onChange}
                  isInvalid={isInvalid}
                  disabled={disabled}
                  options={options}
                  hideAppearanceWhenDisabled={hideAppearanceWhenDisabled}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsFocus(false)
                    }, 200);
                  }}
                  onFocus={() => {
                    setIsFocus(true)
                  }}

                />
                {
                  !showOnly &&
                    <>
                      {
                        onAdd &&
                          <InputGroup.Append>
                              <Button
                                  variant="primary"
                                  disabled={disabled}
                                  onClick={() => {
                                    if (disabled) return;
                                    if (onAdd) {
                                      onAdd();
                                    }
                                  }}
                              >
                                  <FaPlus/>
                              </Button>
                          </InputGroup.Append>
                      }
                        <Form.Label
                            className={labelClassesName}>
                            <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                        </Form.Label>
                        <CleanValue isInvalid={isInvalid} disabled={disabled}
                                    isFocusedAndHasValue={isFocus && hasValue}
                                    clear={() => field.onChange({target: {value: ''}})}
                        />
                    </>
                }

              </InputGroup>

              {rightElement && !showOnly ? rightElement : null}
              <FieldDescription desc={description}/>

              {fieldState.error ? (
                <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                  {getErrorMessage(fieldState.error)}
                </Form.Control.Feedback>
              ) : null}
            </Col>
          </Form.Group>
        );
      }}
    />
  );
};

export const SearchableSelect = (
  {
    id,
    name,
    showOnly,
    rules,
    label,
    shouldUnregister,
    defaultValue = '',
    control,
    onChange,
    disabled = false,
    getDefaultFunction,
    isMulti,
    rightElement,
    hideSelectedOptions,
    isLoading,
    isCreatable,
    description,
    hideLabel = false,
    horizontalLayout,
    renderOnlyControl,
    className = '',
    options,
    loadOptions,
  }: SearchableSelectCommonProps
) => {
  const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={dv}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({field, fieldState}) => {
        const isRequired = Boolean(rules?.required);
        const isInvalid = Boolean(fieldState.error);
        const [randomId, setRandomId] = useState<string>('')
        const [isFocus, setIsFocus] = useState<boolean>(false);

        useEffect(() => {
          if (randomId && (!id || id === randomId)) return;
          setRandomId(id || `form-${(Math.random() + 1).toString(36).substring(7)}`)
        }, [id, randomId]);

        const selectClassName: string = useMemo(() => {
          return classNames(`flex-grow-1 ${isInvalid ? 'is-invalid' : ''}`, className, isFocus ? 'focused' : '');
        }, [isInvalid, isFocus]);

        const hasValue = useMemo(() => hasValidValue(field.value), [field.value])

        const ControlElement = (
          <BaseSelectBoxWithRef
            randomId={randomId}
            required={isRequired}
            className={selectClassName}
            isMulti={isMulti}
            {...field}
            onChange={(value: any) => {
              if (onChange?.(value) === false) {
                return
              }
              field.onChange({
                target: {name, value},
                type: 'change'
              });
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsFocus(false);
              }, 200);
            }}
            onFocus={() => {
              setIsFocus(true)
            }}
            isDisabled={disabled}
            hideSelectedOptions={hideSelectedOptions}
            isLoading={isLoading}
            isCreatable={isCreatable}
            options={options}
            loadOptions={loadOptions}
          />
        )

        const ControlElementWithInputGroup = !!rightElement && !showOnly ? (
          <InputGroup className={'flex-nowrap'}>
            {ControlElement}
            {rightElement}
          </InputGroup>
        ) : ControlElement;

        if (renderOnlyControl && !showOnly) {
          return ControlElementWithInputGroup
        }

        return (
          <Form.Group
            as={Row}
            className={classNames(style.formInput, 'mb-3 floating-form-group searchable-select')}
            controlId={randomId}
          >
            <div id={`${name}-scrollTo`}/>
            {
              showOnly && <Form.Label className={'show-only'} column sm={horizontalLayout ? 4 : 12}>
                    <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                </Form.Label>
            }
            <Col sm={horizontalLayout ? 8 : 12}>
              <InputGroup
                className={classNames(isInvalid ? 'is-invalid' : '', isFocus ? 'focused' : '', description ? 'pb-0' : '', hasValue ? 'has-value' : '', rightElement ? 'with-pre-group' : '')}>
                {
                  showOnly ?
                    <div className={style.showOnlyValue}>{field.value?.label}</div>
                    : <>
                      {ControlElementWithInputGroup}
                      {
                        !hideLabel && <Form.Label
                              className={classNames('floating-label', hasValue ? 'has-value' : '')}>
                              <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                          </Form.Label>
                      }
                      <CleanValue
                        disabled={disabled}
                        isInvalid={isInvalid}
                        isFocusedAndHasValue={isFocus && hasValue}
                        clear={() => {
                          field.onChange({target: {value: isMulti ? [] : ''}});
                          onChange?.(isMulti ? [] : null);
                        }}
                      />
                    </>
                }
              </InputGroup>
              {
                fieldState.error ?
                  <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                    {getErrorMessage(fieldState.error)}
                  </Form.Control.Feedback>
                  : null
              }
              <FieldDescription desc={description}/>
            </Col>
          </Form.Group>
        );
      }}
    />
  );
};

// handle showOnly part if needed
export const Address = (
  {
    name,
    rules,
    label,
    control,
    defaultValue,
    getDefaultFunction,
    disabled = false,
    googleSearch = false,
    setValue,
  }: AddressProps
) => {
  const configuration = configurationState.useValue();
  const {ref} = usePlacesWidget<HTMLInputElement>({
    apiKey: configuration?.google_maps_key,
    onPlaceSelected: (place) => {
      if (!setValue) return;
      const streetNumber = place.address_components?.find((x) =>
        x.types.includes('street_number')
      )?.long_name;
      const streetName = place.address_components?.find((x) =>
        x.types.includes('route')
      )?.long_name;
      const address1 = `${streetNumber ?? ''} ${streetName ?? ''}`;
      setValue(`${name}.address1`, address1);

      const city = place.address_components?.find((x) => x.types.includes('locality'))?.long_name;
      setValue(`${name}.city`, city);

      const state = place.address_components?.find((x) =>
        x.types.includes('administrative_area_level_1')
      )?.short_name;
      setValue(`${name}.state`, state);

      const zip = place.address_components?.find((x) =>
        x.types.includes('postal_code')
      )?.short_name;
      setValue(`${name}.zip`, zip);

      const latitude = place.geometry?.location?.lat();
      const longitude = place.geometry?.location?.lng();
      if (longitude && latitude) {
        setValue(`${name}.latitude`, latitude);
        setValue(`${name}.longitude`, longitude);
      }
    },
    options: {types: ['geocode'], fields: ['geometry.location', 'address_components']},
  });

  const dv = useMemo(() => {
    return getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  }, [getDefaultFunction, defaultValue]);

  // prevent submitting form on enter
  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };
    ref.current?.addEventListener('keydown', callback);
    return () => {
      try {
        ref.current?.removeEventListener('keydown', callback);
      } catch (e) {
      }
    };
  }, [ref.current]);

  return (
    <div>
      <strong>{label}</strong>
      {
        googleSearch && <TextBox
              control={control}
              disabled={disabled}
              label={'Address Search'}
							name={`${name}.addressSearch`}
              ref={ref}/>
      }
      <Hidden
        name={`${name}.id`}
        control={control}
        disabled={disabled}
        defaultValue={dv?.id ?? undefined}
        getDefaultFunction={getDefaultFunction}
      />
      <Hidden
        name={`${name}.addressType`}
        control={control}
        disabled={disabled}
        defaultValue={dv?.addressType ?? 'PHYSICAL'}
        getDefaultFunction={getDefaultFunction}
      />
      <TextBox
        name={`${name}.address1`}
        label={'Street Address'}
        type={'string'}
        control={control}
        rules={rules}
        disabled={disabled}
        defaultValue={dv?.address1 ?? undefined}
        getDefaultFunction={getDefaultFunction}
      />

      <TextBox
        name={`${name}.address2`}
        label={'Address Line 2'}
        type={'string'}
        control={control}
        disabled={disabled}
        defaultValue={dv?.address2 ?? undefined}
        getDefaultFunction={getDefaultFunction}
      />

      <Row>
        <Col>
          <TextBox
            name={`${name}.city`}
            label={'City'}
            type={'string'}
            control={control}
            rules={rules}
            disabled={disabled}
            defaultValue={dv?.city ?? undefined}
            getDefaultFunction={getDefaultFunction}
          />
        </Col>

        <Col>
          <Select
            name={`${name}.state`}
            label={'State'}
            control={control}
            rules={rules}
            options={statesObject}
            disabled={disabled}
            defaultValue={dv?.state ?? Object.keys(statesObject)[0]}
            getDefaultFunction={getDefaultFunction}
          />
        </Col>

        <Col>
          <TextBox
            name={`${name}.zip`}
            label={'Zip'}
            type={'zip'}
            control={control}
            rules={rules}
            disabled={disabled}
            defaultValue={dv?.zip ?? undefined}
            getDefaultFunction={getDefaultFunction}
          />
        </Col>
      </Row>
    </div>
  );
};

// handle showOnly when needed
export const Date = React.forwardRef((
  {
    name,
    rules,
    label,
    showOnly,
    shouldUnregister,
    defaultValue = null,
    readOnlyDateFormat = DateFormats.DATE,
    dateFormat = 'MM/dd/yyyy',
    control,
    disabled = false,
    getDefaultFunction,
    minDate = null,
    maxDate = null,
    description,
    filterDate,
    showTimeSelect,
    onChange,
    horizontalLayout
  }: DateProps,
  ref: ForwardedRef<ReactDatePicker>
) => {
  const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue, 'date') : defaultValue;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={dv}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({field, fieldState}) => {
        const [isFocus, setIsFocus] = useState<boolean>(false);
        const isRequired = Boolean(rules?.required);
        const isInvalid = !!fieldState.error;
        // create a rule for the date that it cannot be a weekend
        const className = useMemo(() => {
          return `${isInvalid ? 'is-invalid' : ''} ${isFocus ? 'focused' : ''} ${description ? 'pb-0' : ''}`
        }, [isFocus, isInvalid])

        const hasValue = useMemo(() => hasValidValue(field.value), [field.value])
        const handleBlurOrClose = () => {
          setTimeout(() => {
            setIsFocus(false);
          }, 200);
        };
        return (
          <Form.Group
            as={Row}
            className={classNames(style.formInput, 'mb-3 floating-form-group')}
            controlId={`form-${field.name}`}
          >
            <div id={`${name}-scrollTo`}/>
            {
              showOnly && <Form.Label className={'show-only'} column sm={horizontalLayout ? 4 : 12}>
                    <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                </Form.Label>
            }
            <Col sm={horizontalLayout ? 8 : 12}>
              {
                showOnly ?
                  (<InputGroup>
                    <div
                      className={style.showOnlyValue}>{field.value ? moment(field.value).format(readOnlyDateFormat) : null}</div>
                  </InputGroup>)
                  : (
                    <InputGroup className={className}
                    >
                      <DatePicker
                        placeholderText="Select date"
                        selected={field.value ? field.value : null}
                        dateFormat={dateFormat}
                        showTimeSelect={showTimeSelect}
                        className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                        minDate={minDate}
                        maxDate={maxDate || stringToDate('9999-12-31 23:59:59')}
                        filterDate={filterDate}
                        autoComplete={'off'}
                        {...field}
                        disabled={disabled}
                        ref={ref}
                        onChange={(date, event) => {
                          onChange?.(date)
                          field.onChange(date, event)
                        }}
                        onFocus={() => setIsFocus(true)}
                        onBlur={handleBlurOrClose}
                        onCalendarClose={handleBlurOrClose}
                        customInput={
                          <Form.Control
                            required={isRequired}
                            type={'text'}
                            {...field}
                            disabled={disabled}
                            ref={field.ref}
                            // isValid={fieldState.isTouched && !isInvalid}
                            isInvalid={isInvalid}
                            autoComplete={'off'}
                          />
                        }
                      />
                      <Form.Label className={classNames(`floating-label`, hasValue ? 'has-value' : '')}>
                        <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                      </Form.Label>

                      {
                        fieldState.error && <div className="border border-danger rounded-circle invalid-circle">
                              <FaExclamation className={'text-danger'} size={11}/>
                          </div>
                      }
                      <CleanValue
                        disabled={disabled}
                        isInvalid={isInvalid}
                        isFocusedAndHasValue={isFocus && hasValue}
                        clear={() => {
                        field.onChange({
                          target: {name, undefined},
                          type: 'change'
                        });
                        setIsFocus(false)
                      }}/>
                    </InputGroup>
                  )
              }
              <FieldDescription desc={description}/>
              {fieldState.error ? (
                <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                  {getErrorMessage(fieldState.error)}
                </Form.Control.Feedback>
              ) : null}
            </Col>
          </Form.Group>
        );
      }}
    />
  );
});

export const CheckboxGroup = (
  {
    name,
    showOnly,
    rules,
    label,
    shouldUnregister,
    defaultValue = '',
    control,
    options,
    onChange,
    disabled = false,
    getDefaultFunction,
    markGroupRequired,
    errors,
    description,
    usePrefix = true
  }: CheckboxGroupProps
) => {
  return (
    <Form.Group
      className={classNames(style.formInput, 'mb-3')}
      controlId={`form-${name}`}
    >
      <div id={`${name}-scrollTo`}/>
      <Form.Label>
        {label}
        {markGroupRequired && !showOnly ? (
          <small className="text-warning" style={{marginLeft: 4}}>
            (Required)
          </small>
        ) : null}
        {description}
      </Form.Label>
      {
        options
          .map(({value, label, isDisabled}, index) => {
            // this prefix should be used when the "value" parameter is a number.
            // When it's a string, we can set usePrefix as false
            const checkboxName = `${name}.${usePrefix ? CHECKBOX_GROUP_PREFIX : ''}${value}`
            const dv = getDefaultFunction ? getDefaultFunction(checkboxName, defaultValue) : defaultValue;

            return (
              <Controller
                key={index}
                name={checkboxName}
                control={control}
                defaultValue={dv}
                rules={{
                  ...rules,
                  required: !isDisabled && rules?.required
                }}
                shouldUnregister={shouldUnregister}
                render={({field, fieldState}) => {
                  const isInvalid = Boolean(fieldState.error);
                  return (
                    <>
                      {
                        showOnly ?
                          field.value && (
                            <div className={style.showOnlyValue}>
                              <div className="badge badge-primary mr-2 mb-2">{label}</div>
                            </div>
                          )
                          : <Form.Check
                            type={'checkbox'}
                            label={label}
                            onChange={(event) => {
                              if (onChange) {
                                onChange(event.target.value, event.target.checked);
                              }
                              field.onChange(event);
                            }}
                            value={value}
                            checked={field.value}
                            isInvalid={isInvalid}
                            id={`form-${field.name}`}
                            name={field.name}
                            disabled={disabled || isDisabled}
                          />}
                    </>
                  );
                }}
              />
            )
          })
      }
      {errors && Object.keys(errors).length ? (
        <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
          {getErrorMessage(errors[Object.keys(errors)[0]])}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export const Checkbox = (
  {
    name,
    showOnly,
    rules,
    label,
    shouldUnregister,
    defaultValue = '',
    control,
    onChange,
    disabled = false,
    getDefaultFunction,
  }: CheckboxProps
) => {
  const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={dv}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({field, fieldState}) => {
        const isInvalid = Boolean(fieldState.error);
        return (
          <Form.Group
            className={classNames(style.formInput, 'mb-3')}
            controlId={`form-${field.name}`}
          >
            <div id={`${name}-scrollTo`}/>
            {
              showOnly ?
                <>
                  <Form.Label>
                    {label}
                  </Form.Label>
                  <div className={style.showOnlyValue}>
                    {`${YesNoEnum[field.value ? 'true' : 'false']}`}
                  </div>
                </>
                :
                <Form.Check
                  type={'checkbox'}
                  label={label}
                  onChange={(event) => {
                    if (onChange) {
                      onChange(event.target.value, event.target.checked);
                    }
                    field.onChange(event);
                  }}
                  checked={field.value}
                  isInvalid={isInvalid}
                  disabled={disabled}
                />
            }
            {fieldState.error ? (
              <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                {getErrorMessage(fieldState.error)}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        );
      }}
    />
  );
};

export const QuillEditorField = React.forwardRef(
  (
    {
      id,
      name,
      showOnly,
      rules,
      label,
      shouldUnregister,
      defaultValue = '',
      control,
      rows,
      onChange,
      readOnly = false,
      getDefaultFunction,
      placeholder,
      onLoadCallback,
      modules,
      autoParagraph = true
    }: QuillEditorFieldProps,
    ref: ForwardedRef<ReactQuill | undefined>
  ) => {
    const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={dv}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({field, fieldState}) => {
          const isRequired = Boolean(rules?.required);
          const isInvalid = Boolean(fieldState.error);
          const quillRef = useRef<ReactQuill>(null);

          useImperativeHandle(ref, () => {
            if (quillRef.current) {
              return quillRef.current
            }
          });

          useEffect(() => {
            if (onLoadCallback) {
              // for example: add dropdowns fields to the toolbar
              onLoadCallback(id);
            }
          }, [id])

          return (
            <Form.Group
              className={classNames(style.formInput, 'mb-3')}
              controlId={`form-${field.name}`}
            >
              <Form.Label
                onClick={() => {
                  quillRef.current?.focus()
                }}
              >
                {label}
                {isRequired && !showOnly ? (
                  <small className="text-warning" style={{marginLeft: 4}}>
                    (Required)
                  </small>
                ) : null}
              </Form.Label>
              <ReactQuill
                ref={quillRef}
                id={id}
                bounds={`.quill`}
                modules={showOnly ? {
                  toolbar: false
                } : modules}
                onChange={(value) => {
                  const noTagValue = value
                    .replace(/<p>(.*)<\/p>/g, '$1')
                    .replace(/<\/p><p>/g, ' ')
                    .replace(/<br>/g, '')
                    .replace(/\s+/g, ' ')
                  // There is text even after removing tags
                  if (noTagValue) {
                    // Set that no tag value as field value
                    // ONLY if it's a no-auto-paragraph field
                    if (!autoParagraph) {
                      value = `<p>${noTagValue}</p>`;
                    }
                  } else {
                    // No value after removing tags,
                    // so the field should be set empty
                    value = noTagValue;
                  }
                  field.onChange({
                    target: {name, value},
                    type: 'change'
                  })
                }}
                placeholder={placeholder}
                value={field.value}
                readOnly={readOnly || showOnly}
                className={`${isInvalid ? 'is-invalid' : ''} ${showOnly ? 'show-only' : ''}`}
              />
              {fieldState.error ? (
                <Form.Control.Feedback type="invalid">
                  {getErrorMessage(fieldState.error)}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          );
        }}
      />
    );
  }
);
type ClearProp = {
  isFocusedAndHasValue: boolean
  disabled?: boolean
  isInvalid: boolean
  clear: () => void
}
export const CleanValue = ({isFocusedAndHasValue, disabled = false, isInvalid, clear}: ClearProp) => {
  return <>
    {
      isInvalid && <div className="border border-danger rounded-circle invalid-circle">
            <FaExclamation className={'text-danger'} size={11}/>
        </div>
    }
    {
      !disabled && isFocusedAndHasValue ?
        <div className="cross-clear" onClick={() => {
          clear && clear()
        }}><FaTimes size={20}/></div>
        : <div className="cross-clear-hide"></div>
    }
  </>
}
export const getLabelClasses = (value: any, disabled: boolean = false) => {
  const classArray: string[] = []
  if (hasValidValue(value)) {
    classArray.push('has-value');
  }
  if (disabled) {
    classArray.push('disabled');
  }
  return classArray.join(' ')
}
const getFieldClasses = (value: any, disableDigitMarker: boolean = false) => {
  const classArray: string[] = []
  if (hasValidValue(value)) {
    classArray.push('has-value');
  }
  if (disableDigitMarker) {
    classArray.push('no-indicator');
  }
  return classArray.join(' ')
};
export const ViewRow = ({horizontalLayout = true, label, value}: ViewOptionProps) => (
  <Row className="m-3 floating-form-group form-group">
    <Form.Label className={'show-only'} column sm={horizontalLayout ? 4 : 12}>
      <span>{label}</span>
    </Form.Label>
    <Col sm={horizontalLayout ? 8 : 12}>
      <InputGroup>
        <div className={style.showOnlyValue}>{value}</div>
      </InputGroup>
    </Col>
  </Row>
);
