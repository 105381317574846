import {windowIsMobileState} from '@state/globalState/windowSize';
import React, {useCallback, useMemo} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import * as Auth from '@server-api/index';
import authenticationBlobAtom, {
	defaultAuthenticationBlob
} from '@state/recoil/authentication/core/authenticationBlobAtom';
import {getBaseUri} from '@common/baseUri';
import NavigationMobile from './Mobile';
import NavigationDesktop from './Desktop';
import WireInstructionModal from "@components/Navigation/WireInstructionModal";
import SageFinancialModal from "@legacy/views/DashboardViewLegacy/component/SageFinancial/SageFinancialModal";
import isFeatureEnabledSelector from "@state/globalState/isFeatureEnabledSelector";
import profileAtom from "@state/recoil/profileAtom";
import tokenToSessionUrl from "@hornet-api/tokenToSessionUrl";


const Navigation = () => {
	const isMobile = windowIsMobileState.useValue();
	const isSageFeatureEnabled = isFeatureEnabledSelector('sage');
	const profile = useRecoilValue(profileAtom);

	const isFundingDashboardApproved = useMemo(() => {
		return profile?.investorProfile?.fundingDashboardAccess === 'APPROVED'
	}, [profile]);

	const setAuthenticationBlob = useSetRecoilState(authenticationBlobAtom);

	const handleOnLogout = useCallback(() => {
		// change url to root to not save current location without redirecting
		window.history.replaceState({}, document.title, window.location.origin);
		Auth.signoffUser(true)
			.then(() => {
				setAuthenticationBlob(defaultAuthenticationBlob());
			});
	}, [setAuthenticationBlob]);

	// for when admin is on same ui
	const handleOnStopImpersonating = useCallback(() => {
		Auth.signoffUser(false)
			.then((user: any) => {
				setAuthenticationBlob((state) => {
					if (user.length === 0) return defaultAuthenticationBlob();
					return {
						...state,
						active_user: user[0].data,
						impersonation_user: null,
					};
				});
				let returnUrl = getBaseUri();
				// use the return url if we have one
				const localStorageUrl = window.localStorage.getItem('impersonateReturnTo');
				if (localStorageUrl) {
					// clear the storage first
					window.localStorage.removeItem('impersonateReturnTo')
					returnUrl = localStorageUrl;
				}
				console.log('returnUrl', returnUrl)

				if (user.length === 0) {
					window.location.href = returnUrl!;
					return;
				}

				window.location.href = tokenToSessionUrl(new URL(returnUrl!, self.location.origin).href, user[0].data.token)
			});
	}, [setAuthenticationBlob]);

  return (
    <>
      {
        isMobile ?
          <NavigationMobile
            onStopImpersonating={handleOnStopImpersonating}
          />
          :
          <NavigationDesktop
            onStopImpersonating={handleOnStopImpersonating}
          />
      }
			{profile && (
				<>
					<WireInstructionModal/>
					{isSageFeatureEnabled && isFundingDashboardApproved && <SageFinancialModal/>}
				</>
			)}
    </>
  );
}

export default Navigation;