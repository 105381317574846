import React from "react";
import {FaLock} from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import style from "./MobileTwoFactorInformation.module.scss";

interface Props {
  isFirebaseEnabled: boolean;
  hasMobileSessionActive: boolean;
  resendMobileNotification: () => void;
}

const MobileTwoFactorInformation = (
  {
    isFirebaseEnabled,
    hasMobileSessionActive,
    resendMobileNotification
  }: Props) => {

  return (
    <Row className={style.rowCustom}>
      <Col xs={2}><FaLock size={50}/></Col>
      {
        isFirebaseEnabled && !hasMobileSessionActive ?
          <Col xs={10}>
            To verify your account, please log in to the <i>Hornet Authenticator Application</i> on your mobile device
            first. Once you’re logged in, return here and click
            <a href="#" onClick={(e) => {
              e.preventDefault();
              resendMobileNotification();
            }} className={'pl-1'}>
              Resend Verification
            </a> to receive the verification
            again.
          </Col>
          :
          <Col xs={10}>
            Open the <i>Hornet Authenticator Application</i> on your mobile device and approve your Sign In Request
          </Col>
      }
    </Row>
  )
}

export default MobileTwoFactorInformation;