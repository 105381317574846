import {Column} from "@admin/components/BootstrapSmartTable";
import {IInsurance, IInsuranceTable} from "@interfaces/insurance";
import {currency, formatDate} from "@common/basic";
import {DateFormats} from "@interfaces/DateFormats";
import {Link} from "react-router-dom";
import React from "react";
import {globalSearchDataState} from "@admin-ui/pages/SearchPage/constant";
import {viewDocumentUrl} from "@hornet-api/contact/document/documentRequestAction";
import openUrlWithToken from "@hornet-api/openUrlWithToken";
import {onViewClick} from "@common/utils/fileUtil";
import {PublicPrivateEnum, YesNoEnum} from "@interfaces/Enums";
import {
  InsuranceCoverageStatusEnumDescription,
  InsuranceCoverageStatusEnumDisplay,
  InsuranceCoverageTypeEnumDescription,
  InsuranceCoverageTypeEnumDisplay,
  InsuranceDocumentTypeEnumDescription,
  InsuranceDocumentTypeEnumDisplay
} from "@admin-ui/pages/InsurancePage/components/constant";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export const convertInsuranceDataToTableData = (insuranceList: IInsurance[],  titleEndorsementMap: {[p: number]: string}): IInsuranceTable[] => {
  const globalSearchData = globalSearchDataState.get();

  return insuranceList.map(insurance => ({
    ...insurance,
    carrierName: globalSearchData["ENTITY"][insurance.carrierNameId]?.text || "",
    carrierAgent: insurance.carrierAgentContactId && globalSearchData["CONTACT"][insurance.carrierAgentContactId]?.text || "",
    documentNames: insurance.documents.map(document => document.fileName).join(", "),
    coveredProperties: insurance.coveredPropertyIds.map(id => ({id: id, text: globalSearchData["PROPERTY"][id]?.text || ""})),
    coveredPropertyNames: insurance.coveredPropertyIds.map(id => globalSearchData["PROPERTY"][id]?.text || "").join(", "),
    titleEndorsements: insurance.titleEndorsementIds.map(id => ({id: id, text: titleEndorsementMap[id]})),
    titleEndorsementName: insurance.titleEndorsementIds.map(id => titleEndorsementMap[id] || "").join(", "),
  }))
}

export const columns: Column<IInsuranceTable>[] = [
  {
    id: 'id',
    title: 'Insurance',
    field: 'id',
    render: row => <Link to={`/insurance/show/${row.id}`}>View</Link>,
  },
  {
    id: 'cT',
    title: 'Coverage Type',
    field: 'coverageType',
    filter: true,
    lookup: InsuranceCoverageTypeEnumDisplay,
    render: row => (
      <OverlayTrigger
        placement="top-end"
        overlay={(
          <Tooltip id={`insurance-coverage-type-${row.id}`}>
            {InsuranceCoverageTypeEnumDescription[row.coverageType]}
          </Tooltip>
        )}
      >
        <text>{InsuranceCoverageTypeEnumDisplay[row.coverageType]}</text>
      </OverlayTrigger>
    )
  },
  {
    id: 'dT',
    title: 'Document Type',
    field: 'documentType',
    filter: true,
    lookup: InsuranceDocumentTypeEnumDisplay,
    render: row => (
      <OverlayTrigger
        placement="top-end"
        overlay={(
          <Tooltip id={`insurance-document-type-${row.id}`}>
            {InsuranceDocumentTypeEnumDescription[row.documentType]}
          </Tooltip>
        )}
      >
        <text>{InsuranceDocumentTypeEnumDisplay[row.documentType]}</text>
      </OverlayTrigger>
    )
  },
  {
    id: 'cA',
    title: 'Coverage Amount',
    field: 'coverageAmount',
    filter: true,
    render: row => currency(row.coverageAmount, "0.00", true),
  },
  {
    id: 'cSD',
    title: 'Coverage Start Date',
    field: 'coverageStartDate',
    filter: true,
    render: row => formatDate(row.coverageStartDate, DateFormats.SEMI),
  },
  {
    id: 'cED',
    title: 'Coverage Expiration Date',
    field: 'coverageExpirationDate',
    filter: true,
    render: row => formatDate(row.coverageExpirationDate, DateFormats.SEMI),
  },
  {
    id: 'cS',
    title: 'Coverage Status',
    field: 'coverageStatus',
    filter: true,
    lookup: InsuranceCoverageStatusEnumDisplay,
    render: row => (
      <OverlayTrigger
        placement="top-end"
        overlay={(
          <Tooltip id={`insurance-coverage-status-${row.id}`}>
            {InsuranceCoverageStatusEnumDescription[row.coverageStatus]}
          </Tooltip>
        )}
      >
        <text>{InsuranceCoverageStatusEnumDisplay[row.coverageStatus]}</text>
      </OverlayTrigger>
    )
  },
  {
    id: 'cP',
    title: 'Covered Properties',
    field: 'coveredPropertyNames',
    filter: true,
    render: row => (<>
        {
          row.coveredProperties.map(({id, text}) => (
            <div key={id}><Link to={`/property/show/${id}`}>{text}</Link></div>
          ))
        }
      </>
    ),
  },
  {
    id: 'cN',
    title: 'Carrier Name',
    field: 'carrierName',
    filter: true,
    render: row => <Link to={`/entity/show/${row.carrierNameId}`}>{row.carrierName}</Link>,
  },
  {
    id: 'cAC',
    title: 'Carrier Agent',
    field: 'carrierAgent',
    filter: true,
    render: row => row.carrierAgentContactId && <Link to={`/contact/show/${row.carrierAgentContactId}`}> {row.carrierAgent} </Link>
  },
  {
    id: 'pN',
    title: 'Policy Number',
    field: 'policyNumber',
    filter: true,
  },
  {
    id: 'iP',
    title: 'Insurance Premium',
    field: 'insurancePremium',
    filter: true,
    render: row => currency(row.insurancePremium, "0.00", false),
  },
  {
    id: 'doc',
    title: 'Documents',
    field: 'documentNames',
    filter: true,
    render: row => (<>
        {
          row.documents.map(({id, fileName}) => (
            <a
              key={id}
              href={openUrlWithToken(viewDocumentUrl(id))}
              className="d-block"
              onClick={event => {
                event.preventDefault();
                onViewClick(id, fileName);
              }}
            >
              {fileName}
            </a>
          ))
        }
      </>
    ),
  },
  {
    id: 'iW',
    title: 'Waived?',
    field: 'isWaived',
    filter: true,
    lookup: YesNoEnum,
  },
  {
    id: 'aL',
    title: 'Associated Loans',
    field: 'associatedLoanIds',
    filter: true,
    render: row => (<>
        {
          row.associatedLoanIds.map(aliasId => (
            <div key={aliasId}><Link to={`/loan/show/${aliasId}`}>{aliasId}</Link></div>
          ))
        }
      </>
    ),
  },
  {
    id: 'iPub',
    title: 'Public?',
    field: 'isPublic',
    filter: true,
    lookup: PublicPrivateEnum,
  },
  {
    id: 'tE',
    title: 'Endorsements',
    field: 'titleEndorsementName',
    filter: true,
    render: row => (<>
        {
          row.titleEndorsements.map(({id, text}) => (
            <div key={id}><Link to={`/titleEndorsement/edit/${id}`}>{text}</Link></div>
          ))
        }
      </>
    ),
  },
  {
    id: 'exc',
    title: 'Exceptions',
    field: 'exceptions',
    filter: true,
  },
  {
    id: 'note',
    title: 'Notes',
    field: 'notes',
    filter: true,
  },
];
