import axios from 'axios';
import getPublicApiPath from "@hornet-api/getPublicApiPath";

type UnsubscribeSuccessResponse = {
  error: boolean;
  fullName: string;
}

type UnsubscribeErrorResponse = {
  error: boolean;
  message: string;
}
const unsubscribe = async (token: string) => {
  const url = `${getPublicApiPath()}/handle-unsubscribe-link`;
  return axios.post<UnsubscribeSuccessResponse | UnsubscribeErrorResponse>(url, {token}).then(res => res.data);
};

export default unsubscribe;
