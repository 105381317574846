import {YesNoTextEnum} from "@interfaces/Enums";

export interface Feedback {
  id: number;
  contactId: number;
  contactFullName: string;
  isImpersonation: YesNoTextEnum;
  page: string;
  subPage: string | null;
  dateCreated: string;
  comment: string;
}

export interface FeedbackDetail {
  contactFullName: string;
  contactId: number;
  impersonationUser: string;
  impersonationUserId: number;
  comment: string;
  dateCreated: string;
  page: string;
  subPage: string;
}

export interface SubmitFeedbackForm {
  comment: string;
}


export interface SubmitFeedbackServer {
  comment: string;
  adminUser?: string;
  page: string;
  subPage?: string;
}

export const defaultSubmitFeedback: SubmitFeedbackForm = {
  comment: ''
}
