import axios from 'axios';
import { getCleanBaseUri } from '@common/baseUri';

export type RoleGroup = {
  id: number;
  name: string;
};

const getRegistrationRoleGroups = async (userInterface?:number|null) => {
  const url = `${getCleanBaseUri()}/api/public/registrationRoleGroups${userInterface ? `?userInterface=${userInterface}` : ''}`;
  const response = await axios.get(url);
  return response.data as RoleGroup[];
};

export default getRegistrationRoleGroups;
