import {
  InsuranceCoverageStatusEnum,
  InsuranceCoverageTypeEnum,
  InsuranceDocumentTypeEnum,
  InsuranceExpirationTypeEnum
} from "@interfaces/GeneratedEnums";
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";
import {PublicPrivateEnum, YesNoEnum} from "@interfaces/Enums";
import {booleanToString, formatDateForServer, formatDecimals} from "@common/basic";
import {IInsurance} from "@interfaces/insurance";
import {IGlobalSearchState, searchableObjectToOption} from "@admin-ui/pages/SearchPage/constant";
import moment from "moment";

export const InsuranceCoverageTypeEnumDisplay = Object.fromEntries(
  Object.entries(InsuranceCoverageTypeEnum).map(([key, value]) => [key, value.split(' - ')[0]])
) as { [key in keyof typeof InsuranceCoverageTypeEnum]: string };

export const InsuranceDocumentTypeEnumDisplay = Object.fromEntries(
  Object.entries(InsuranceDocumentTypeEnum).map(([key, value]) => [key, value.split(' - ')[0]])
) as { [key in keyof typeof InsuranceDocumentTypeEnum]: string };

export const InsuranceCoverageStatusEnumDisplay = Object.fromEntries(
  Object.entries(InsuranceCoverageStatusEnum).map(([key, value]) => [key, value.split(' - ')[0]])
) as { [key in keyof typeof InsuranceCoverageStatusEnum]: string };


export const InsuranceCoverageTypeEnumDescription = Object.fromEntries(
  Object.entries(InsuranceCoverageTypeEnum).map(([key, value]) => [key, value.split(' - ')[1] || value])
) as { [key in keyof typeof InsuranceCoverageTypeEnum]: string };

export const InsuranceDocumentTypeEnumDescription = Object.fromEntries(
  Object.entries(InsuranceDocumentTypeEnum).map(([key, value]) => [key, value.split(' - ')[1] || value])
) as { [key in keyof typeof InsuranceDocumentTypeEnum]: string };

export const InsuranceCoverageStatusEnumDescription = Object.fromEntries(
  Object.entries(InsuranceCoverageStatusEnum).map(([key, value]) => [key, value.split(' - ')[1] || value])
) as { [key in keyof typeof InsuranceCoverageStatusEnum]: string };

interface IInsuranceForm {
  id: number | null;
  coverageType: SelectOptionProps | string;
  documentType: SelectOptionProps | string;
  coverageAmount: string;
  coverageStartDate: Date | null;
  coverageExpirationDate: Date | null;
  expirationType: keyof typeof InsuranceExpirationTypeEnum;
  coverageStatus: keyof typeof InsuranceCoverageStatusEnum | string;
  coveredProperties: SelectOptionProps[];
  carrierName: SelectOptionProps | string;
  carrierAgent: SelectOptionProps | string;
  policyNumber: string;
  insurancePremium: string;
  documentsToUpload: File[];
  isWaived: keyof typeof YesNoEnum;
  associatedLoans: SelectOptionProps[];
  isPublic: keyof typeof PublicPrivateEnum;
  titleEndorsements: SelectOptionProps[];
  exceptions: string;
  notes: string;
}

const defaultFormValue: IInsuranceForm = {
  id: null,
  coverageType: '',
  documentType: '',
  coverageAmount: '0.00',
  coverageStartDate: null,
  coverageExpirationDate: null,
  expirationType: 'COVERAGE_EXPIRES',
  coverageStatus: '',
  coveredProperties: [],
  carrierName: '',
  carrierAgent: '',
  policyNumber: '',
  insurancePremium: '0.00',
  documentsToUpload: [],
  isWaived: "false",
  associatedLoans: [],
  isPublic: "false",
  titleEndorsements: [],
  exceptions: "",
  notes: "",
}

const getCoverageStatus = (coverageStartDate: Date, coverageExpirationDate: Date, documentType: string): keyof typeof InsuranceCoverageStatusEnum => {
  if (documentType === "REQUESTED") {
    return "NOT_COVERED";
  }

  const today = moment();
  if (today.isBefore(coverageStartDate) || today.isAfter(coverageExpirationDate)) {
    return 'NOT_COVERED';
  }
  if (moment(coverageExpirationDate).diff(today, 'days') <= 30) {
    return 'EXPIRING';
  }
  return 'COVERED';
}

const convertServerDataToFormData = (
  serverData: IInsurance,
  globalSearchData: IGlobalSearchState,
  titleEndorsementOptions: { value: number, label: string }[]
): IInsuranceForm => {
  return {
    id: serverData.id,
    coverageType: {
      value: serverData.coverageType,
      label: InsuranceCoverageTypeEnumDisplay[serverData.coverageType],
      tooltip: InsuranceCoverageTypeEnumDescription[serverData.coverageType]
    },
    documentType: {
      value: serverData.documentType,
      label: InsuranceDocumentTypeEnumDisplay[serverData.documentType],
      tooltip: InsuranceDocumentTypeEnumDescription[serverData.documentType]
    },
    coverageAmount: formatDecimals(serverData.coverageAmount),
    coverageStartDate: new Date(serverData.coverageStartDate),
    coverageExpirationDate: new Date(serverData.coverageExpirationDate),
    expirationType: serverData.expirationType,
    coverageStatus: serverData.coverageStatus,
    coveredProperties: serverData.coveredPropertyIds.map((id) => searchableObjectToOption(id, globalSearchData.PROPERTY)),
    carrierName: searchableObjectToOption(serverData.carrierNameId, globalSearchData.ENTITY),
    carrierAgent: '',
    policyNumber: serverData.policyNumber,
    insurancePremium: formatDecimals(serverData.insurancePremium),
    documentsToUpload: [],
    isWaived: booleanToString(serverData.isWaived),
    associatedLoans: serverData.associatedLoanIds.map((id) => searchableObjectToOption(id, globalSearchData.LOAN)),
    isPublic: booleanToString(serverData.isPublic),
    titleEndorsements: titleEndorsementOptions.filter(option => serverData.titleEndorsementIds.includes(option.value)),
    exceptions: serverData.exceptions,
    notes: serverData.notes,
  }
}

const convertFormDataToServerData = (formData: IInsuranceForm) => ({
  id: formData.id,
  coverageType: typeof formData.coverageType === "object" && "value" in formData.coverageType ? formData.coverageType.value : '',
  documentType: typeof formData.documentType === "object" && "value" in formData.documentType ? formData.documentType.value : '',
  coverageAmount: formData.coverageAmount,
  coverageStartDate: formatDateForServer(formData.coverageStartDate),
  coverageExpirationDate: formatDateForServer(formData.coverageExpirationDate),
  expirationType: formData.expirationType,
  coverageStatus: formData.coverageStatus,
  coveredPropertyIds: formData.coveredProperties.map(coveredProperty => coveredProperty.value),
  carrierName: typeof formData.carrierName === "object" && "value" in formData.carrierName && formData.carrierName.value,
  carrierAgent: typeof formData.carrierAgent === "object" && "value" in formData.carrierAgent ? formData.carrierAgent.value : '',
  policyNumber: formData.policyNumber,
  insurancePremium: formData.insurancePremium,
  documentsToUpload: formData.documentsToUpload,
  isWaived: formData.isWaived === 'true',
  associatedLoanIds: formData.associatedLoans.map(loan => loan.value),
  isPublic: formData.isPublic === 'true',
  titleEndorsementIds: formData.titleEndorsements.map(endorsement => endorsement.value),
  exceptions: formData.exceptions,
  notes: formData.notes,
});


export type {IInsuranceForm}
export {
  defaultFormValue,
  convertServerDataToFormData,
  convertFormDataToServerData,
  getCoverageStatus
}
