import {EmailTriggerEnum} from "@interfaces/GeneratedEnums";
import {FaSackDollar} from "react-icons/fa6";
import {BiMessage} from "react-icons/bi";
import React from "react";
import {FaTasks} from "react-icons/fa";
import {TbMailDollar} from "react-icons/tb";

interface INotificationIconMapKey {
  triggers: Array<keyof typeof EmailTriggerEnum>
  icon: JSX.Element | string
}

const NotificationIconMap: INotificationIconMapKey[] = [
  {
    triggers: [
      'LOAN_STAGE_ACTIVE', 'LOAN_STAGE_CLOSED', 'LOAN_UPDATED_PAYOFF_REQUEST', 'LOAN_DOCUMENT_UPLOADED',
      'LOAN_UPDATED_CONSTRUCTION_MAJOR_DELAYS', 'LOAN_UPDATED_CONSTRUCTION_MINOR_DELAYS',
      'LOAN_OVERALL_STATUS_WATCH_LIST',
    ],
    icon: 'usd'
  },
  {
    triggers: ['BUDGET_APPROVED', 'BUDGET_SUBMITTED'],
    icon: <FaSackDollar/>
  },
  {
    triggers: ['TASK_ADMIN', 'TASK_USER', 'FUND_TASK_ADMIN', 'FUND_TASK_USER', 'LPA_TASK_ADMIN', 'LPA_TASK_USER', 'COMMON_TASK_USER', 'COMMON_TASK_ADMIN'],
    icon: <FaTasks/>
  },
  {
    triggers: ['SUBSCRIPTION_FUNDED'],
    icon: 'tasks'
  },
  {
    triggers: ['CHAT_USER'],
    icon: <BiMessage/>
  },
  {
    triggers: ['LPA_SUBMITTED', 'LPA_FUNDED', 'LPA_PAID_OFF', 'LPA_APPROVED'],
    icon: 'file-text'
  },
  {
    triggers: [
      'STATUS_ACH_COMPLETE', 'STATUS_WIRE_TRANSFER_PENDING', 'STATUS_WIRE_COMPLETE', 'STATUS_ACH_AUTHORIZED',
      'STATUS_ACH_AUTHORIZED_PL_P', 'STATUS_ACH_AUTHORIZED_PL_B'
    ],
    icon: 'exchange'
  },
  {
    triggers: ['DRAW_REQUEST_SUBMITTED', 'DRAW_REQUEST_APPROVED'],
    icon: <TbMailDollar/>
  },
  {
    triggers: ['FUNDING_DASHBOARD_APPROVED', 'FUNDING_DASHBOARD_DENIED'],
    icon: 'money'
  }
]

export const getIconByTrigger = (key: keyof typeof EmailTriggerEnum) => (
  NotificationIconMap.find(item => item.triggers.includes(key))?.icon
);
