import {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import profileAtom from '@state/recoil/profileAtom';
import Contact from '@interfaces/Contact';
import {ReferralStatusEnum} from "@interfaces/GeneratedEnums";

/**
 * Returns true if the referral is roadblocked
 */
const isReferralRoadblockedSelector = () => {
  const profile = useRecoilValue(profileAtom) as Contact | null;
  return useMemo(() => {
    if (!profile?.referral) {
      return false;
    }
    const blockingStatuses: Array<keyof typeof ReferralStatusEnum> = [
      'PROCESSING', 'EMAIL_SENT', 'EMAIL_OPENED', 'REGISTERED_IN_PORTAL'
    ];

    return blockingStatuses.includes(profile.referral.status) ||
      (
        profile.referral.status === 'QUALIFIED_NO_ZOOM_SCHEDULED' &&
        !profile?.referral?.isChatPageVisited
      );
  }, [profile?.referral?.status, profile?.referral?.isChatPageVisited]);
};

export default isReferralRoadblockedSelector;
