/* tslint:disable */
/* eslint-disable */

// Generated using typescript-generator version 2.37.1128 on 2025-02-23 23:12:18.

export enum ACHCategoryEnum {
    FUND_ACH = 'FUND',
    LPA_ACH = 'LPA',
    LOAN_PAYMENT_ACH = 'Loan Payment',
}

export enum ACHStatusEnum {
    ACH_PENDING_AUTHORIZATION = 'Pending Authorization',
    WIRE_TRANSFER_PENDING = 'Using Wire Transfer',
    ACH_AUTHORIZED = 'ACH Transfer Processing',
    ACH_UNAUTHORIZED = 'ACH Transfer Cancelled',
    ACH_ERROR = 'ACH Requires Assistance',
    ACH_COMPLETE = 'ACH Complete',
    WIRE_COMPLETE = 'Wire Transfer Complete',
    ACH_UNAUTHORIZED_BY_USER = 'ACH Cancelled by Contact',
}

export enum ACHTriggerEnum {
    DAILY_RUN = 'Daily Run',
    FORCE_RUN = 'Force Run',
    NACHA_DOWNLOAD = 'NACHA Download',
    NOT_PROCESSED = 'Not Processed',
}

export enum ACHWhenToTransferEnum {
    NOW = 'Now - Wire transfer will be initiated immediately upon submission of this form',
    ON_DATE = 'On Date - Wire transfer will be initiated on the date you specify',
    LATER = 'Do not Transfer - Do not take any action right now. You will be able to transfer funds later',
    MANUALLY = 'Manually - Manually transfer funds. Wiring information will be provided',
}

export enum AccountTypeEnum {
    C = 'Checking',
    S = 'Savings',
}

export enum AccreditedInvestorStatusOfEntityEnum {
    UNKNOWN = 'Unknown',
    NON_ACCREDITED = 'Non Accredited',
    ENTITY_OF_ACCREDITED_INVESTORS = 'Accredited: Entity Owners are all Accredited',
    ACCREDITED_ENTITY_FINANCIAL = 'Accredited: Entity Financial Entity',
    ACCREDITED_ENTITY_ASSETS = 'Accredited: Entity Assets',
    ACCREDITED_ENTITY_ADVISOR = 'Accredited: Entity Investment Advisor',
}

export enum AccreditedInvestorStatusOfIndividualEnum {
    UNKNOWN = 'Unknown',
    NON_ACCREDITED = 'Non Accredited',
    ACCREDITED = 'Accredited: Individual Financial',
    ACCREDITED_PROFESSIONAL = 'Accredited: Individual Professional',
}

export enum AddressTypeEnum {
    PHYSICAL = 'PHYSICAL',
    MAILING = 'MAILING',
}

export enum AssetReportStatusEnum {
    PENDING = 'PENDING',
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
}

export enum AssetsCodeEnum {
    PRODUCT_READY = 'PRODUCT_READY',
    ERROR = 'ERROR',
}

export enum AuthCodeEnum {
    AUTOMATICALLY_VERIFIED = 'AUTOMATICALLY_VERIFIED',
    VERIFICATION_EXPIRED = 'VERIFICATION_EXPIRED',
}

export enum BankAccountDepositMethodEnum {
    WIRE_AND_ACH = 'Wire and ACH',
    WIRE_ONLY = 'Wire Only',
    ACH_ONLY = 'ACH Only',
}

export enum BankAccountTypeEnum {
    CHECKING = 'Checking',
    SAVINGS = 'Savings',
}

export enum BankTransactionTypeEnum {
    CREDIT = 'Credit',
    DEBIT = 'Debit',
}

export enum BankTransfersCodeEnum {
    BANK_TRANSFERS_EVENTS_UPDATE = 'BANK_TRANSFERS_EVENTS_UPDATE',
}

export enum BorrowerApplicationStatusEnum {
    DRAFT = 'Draft',
    AWAITING_SIGNATURE = 'Awaiting Signature',
    SUBMITTED = 'Submitted',
    DENIED = 'Denied',
    APPROVED = 'Approved',
}

export enum BorrowerInvoiceStatusEnum {
    CREATED = 'Created',
    QUEUED = 'Queued',
    SUCCESS = 'Success',
    FAILURE = 'Failure',
}

export enum BudgetStatusEnum {
    CREATED = 'Created',
    REQUESTED_CHANGE = 'Change Requested',
    SUBMITTED = 'Submitted',
    APPROVED = 'Approved',
    DENIED = 'Denied',
}

export enum ChatTypesEnum {
    TASK_CHAT = 'taskChat',
    CONTACT_CHAT = 'contactChat',
    LOAN_CHAT = 'loanChat',
}

export enum CitizenshipEnum {
    US_CITIZEN = 'US Citizen',
    PERMANENT_RESIDENT_ALIEN = 'Permanent Resident Alien',
    FOREIGN_NATIONAL = 'Foreign National',
    NO_ANSWER = 'No Answer',
}

export enum ClienteleTypeEnum {
    CONTACT = 'Contact',
    ENTITY = 'Entity',
}

export enum ConstructionStatusEnum {
    NONE = 'None',
    PROGRESSING_NORMALLY = 'Progressing Normally',
    MINOR_DELAYS = 'Minor Delays',
    MAJOR_DELAYS = 'Major Delays',
    COMPLETE = 'Complete',
}

export enum ConstructionTypeEnum {
    REMODEL = 'Remodel',
    NEW_BUILD = 'New Build',
    RENTAL = 'Rental',
    LAND_DEVELOPMENT = 'Land Development',
    NONE = 'None',
}

export enum ContactSettingEnum {
    ADMIN_DASHBOARD_FILTERS = 'Admin Dashboard Filters',
}

export enum ConvertAToBEnum {
    Q1 = 'Q1',
    Q2 = 'Q2',
    Q3 = 'Q3',
    Q4 = 'Q4',
}

export enum CreditBureauEnum {
    EQUIFAX = 'Equifax',
    TRANSUNION = 'TransUnion',
    EXPERIAN = 'Experian',
}

export enum CreditScoreSourceEnum {
    SELF_REPORTED = 'SELF_REPORTED',
    TRANSUNION = 'TRANSUNION',
}

export enum DashboardAccessEnum {
    PENDING = 'Pending',
    APPROVED = 'Approved',
    DENIED = 'Denied',
    NO_REQUEST = 'Not Requested',
}

export enum DataFormatEnum {
    DEFAULT = 'Default',
    CURRENCY = 'Currency',
    SSN = 'SSN',
    EIN = 'EIN',
    EMAIL = 'Email',
    DATE = 'Date',
    PHONE = 'Phone',
    PERCENT = 'Percent',
}

export enum DaysInYearEnum {
    DAYS_360 = '360 Days',
    DAYS_365 = '365 Days',
}

export enum DepositSwitchCodeEnum {
    SWITCH_STATE_UPDATE = 'SWITCH_STATE_UPDATE',
}

export enum DistributionElectionEnum {
    CASH = 'Cash',
    REINVEST = 'Reinvest',
}

export enum DocumentRequestStatusEnum {
    PENDING = 'PENDING',
    VIEWED = 'VIEWED',
    COMPLETE = 'COMPLETE',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    APPROVED = 'APPROVED',
    DENIED = 'DENIED',
}

export enum DocumentTypeEnum {
    BORROWER_INVOICE = 'BORROWER_INVOICE',
    NDA = 'NDA',
    CREDIT = 'CREDIT',
    DRIVER_LICENSE = 'DRIVER_LICENSE',
    SUBSCRIPTION_AGREEMENT = 'SUBSCRIPTION_AGREEMENT',
    FREEFORM = 'FREEFORM',
    NOTARY = 'NOTARY',
    LOAN_PARTICIPATION_AGREEMENT = 'LOAN_PARTICIPATION_AGREEMENT',
    PPM = 'PPM',
    AMBASSADOR_PROGRAM_AGREEMENT = 'AMBASSADOR_PROGRAM_AGREEMENT',
    LOAN_APPLICATION = 'LOAN_APPLICATION',
    DRAW_REQUEST = 'DRAW_REQUEST',
    PAYOFF_REQUEST = 'PAYOFF_REQUEST',
    ESIGN = 'ESIGN',
    LETTER_OF_INTENT = 'LETTER_OF_INTENT',
}

export enum DrawRequestStatusEnum {
    SUBMITTED = 'Submitted, Awaiting Approval',
    APPROVED = 'Approved, Awaiting Payment',
    PAID = 'Paid',
    DENIED = 'Denied',
}

export enum EmailLogEnum {
    CLICKED = 'Clicked',
    DELIVERED = 'Delivered',
    FAILED = 'Failed',
    OPENED = 'Opened',
    QUEUED = 'Message Queued',
    SPAM = 'Spam Complaints',
    UNKNOWN = 'Unknown',
    UNSUBSCRIBED = 'Unsubscribed',
}

export enum EmailTriggerEnum {
    BUDGET_CREATED = 'Budget Status - Created',
    BUDGET_REQUESTED_CHANGE = 'Budget Status - Change Requested',
    BUDGET_SUBMITTED = 'Budget Status - Submitted',
    BUDGET_APPROVED = 'Budget Status - Approved',
    BUDGET_DENIED = 'Budget Status - Denied',
    BUDGET_NEW_COMMENT = 'Budget Comment - Added',
    DRAW_REQUEST_SUBMITTED = 'Draw Request Status - Submitted, Awaiting Approval',
    DRAW_REQUEST_APPROVED = 'Draw Request Status - Approved, Awaiting Payment',
    DRAW_REQUEST_PAID = 'Draw Request Status - PAID',
    DRAW_REQUEST_DENIED = 'Draw Request Status - Denied',
    OTU_PAY_LINK_CREATED = 'One Time Use Pay Link - Created',
    ACH_DELETED = 'ACH Status - Deleted',
    ACH_EDITED = 'ACH Status - Edited',
    STATUS_ACH_PENDING_AUTHORIZATION = 'ACH Status - Pending Authorization',
    STATUS_WIRE_TRANSFER_PENDING = 'ACH Status - Using Wire Transfer',
    STATUS_ACH_AUTHORIZED = 'ACH Status - ACH Transfer Processing',
    STATUS_ACH_UNAUTHORIZED = 'ACH Status - ACH Transfer Cancelled',
    STATUS_ACH_ERROR = 'ACH Status - ACH Requires Assistance',
    STATUS_ACH_COMPLETE = 'ACH Status - ACH Complete',
    STATUS_WIRE_COMPLETE = 'ACH Status - Wire Transfer Complete',
    STATUS_RECURRING_ACH_COMPLETE = 'ACH Status - ACH Recurring Complete',
    ACH_DELETED_PL_P = 'ACH Status (Pay Link) For Payer - Deleted',
    ACH_EDITED_PL_P = 'ACH Status (Pay Link) For Payer - Edited',
    STATUS_ACH_PENDING_AUTHORIZATION_PL_P = 'ACH Status (Pay Link) For Payer - Pending Authorization',
    STATUS_WIRE_TRANSFER_PENDING_PL_P = 'ACH Status (Pay Link) For Payer - Using Wire Transfer',
    STATUS_ACH_AUTHORIZED_PL_P = 'ACH Status (Pay Link) For Payer - ACH Transfer Processing',
    STATUS_ACH_UNAUTHORIZED_PL_P = 'ACH Status (Pay Link) For Payer - ACH Transfer Cancelled',
    STATUS_ACH_ERROR_PL_P = 'ACH Status (Pay Link) For Payer - ACH Requires Assistance',
    STATUS_ACH_COMPLETE_PL_P = 'ACH Status (Pay Link) For Payer - ACH Complete',
    STATUS_WIRE_COMPLETE_PL_P = 'ACH Status (Pay Link) For Payer - Wire Transfer Complete',
    ACH_DELETED_PL_B = 'ACH Status (Pay Link) For Borrower - Deleted',
    ACH_EDITED_PL_B = 'ACH Status (Pay Link) For Borrower - Edited',
    STATUS_ACH_PENDING_AUTHORIZATION_PL_B = 'ACH Status (Pay Link) For Borrower - Pending Authorization',
    STATUS_WIRE_TRANSFER_PENDING_PL_B = 'ACH Status (Pay Link) For Borrower - Using Wire Transfer',
    STATUS_ACH_AUTHORIZED_PL_B = 'ACH Status (Pay Link) For Borrower - ACH Transfer Processing',
    STATUS_ACH_UNAUTHORIZED_PL_B = 'ACH Status (Pay Link) For Borrower - ACH Transfer Cancelled',
    STATUS_ACH_ERROR_PL_B = 'ACH Status (Pay Link) For Borrower - ACH Requires Assistance',
    STATUS_ACH_COMPLETE_PL_B = 'ACH Status (Pay Link) For Borrower - ACH Complete',
    STATUS_WIRE_COMPLETE_PL_B = 'ACH Status (Pay Link) For Borrower - Wire Transfer Complete',
    LPA_SUBMITTED = 'LPA Status - 1. SUBMITTED',
    LPA_PENDING = 'LPA Status - 2. PROCESSING',
    LPA_APPROVED = 'LPA Status - 3. AWAITING SIGNATURES',
    LPA_DENIED = 'LPA Status - 4. AWAITING FUNDING',
    LPA_FUNDED = 'LPA Status - 5. FUNDED',
    LPA_PAID_OFF = 'LPA Status - 6. PAID OFF',
    LPA_SIGNED = 'LPA Status - 7. LPA Signed',
    LPA_BEGIN_DD = 'LPA Begin Due Diligence',
    SUBSCRIPTION_SUBMITTED = 'Subscription Status - SUBMITTED',
    SUBSCRIPTION_PROCESSING = 'Subscription Status - PROCESSING',
    SUBSCRIPTION_AWAITING_SIGNATURES = 'Subscription Status - AWAITING SIGNATURES',
    SUBSCRIPTION_AWAITING_FUNDING = 'Subscription Status - AWAITING FUNDING',
    SUBSCRIPTION_SCHEDULED_RECURRING = 'Subscription Status - SCHEDULED RECURRING',
    SUBSCRIPTION_FUNDED = 'Subscription Status - FUNDED',
    LOAN_STAGE_APPLICATION_RECEIVED = 'Loan Stage - 1. APPLICATION RECEIVED',
    LOAN_STAGE_GENERATING_TERMS = 'Loan Stage - 2. GENERATING TERMS',
    LOAN_STAGE_LOI_AWAITING_SIGNATURE = 'Loan Stage - 3. LOI AWAITING SIGNATURE',
    LOAN_STAGE_PROCESSING = 'Loan Stage - 4. PROCESSING',
    LOAN_STAGE_UNDERWRITING = 'Loan Stage - 5. UNDERWRITING',
    LOAN_STAGE_READY_TO_CLOSE = 'Loan Stage - 6. READY TO CLOSE',
    LOAN_STAGE_FUNDED = 'Loan Stage - 7. FUNDED',
    LOAN_STAGE_ACTIVE = 'Loan Stage - 8. ACTIVE',
    LOAN_STAGE_CLOSED = 'Loan Stage - 9. CLOSED',
    LOAN_STAGE_APPLICATION_DENIED = 'Loan Stage - APPLICATION DENIED',
    LOAN_UPDATED_PAYOFF_REQUEST = 'Loan Updated - Payoff Request Date',
    LOAN_UPDATED_CONSTRUCTION_NONE = 'Loan Updated - Construction None',
    LOAN_UPDATED_CONSTRUCTION_PROGRESSING_NORMALLY = 'Loan Updated - Construction Progressing Normally',
    LOAN_UPDATED_CONSTRUCTION_MINOR_DELAYS = 'Loan Updated - Construction Minor Delays',
    LOAN_UPDATED_CONSTRUCTION_MAJOR_DELAYS = 'Loan Updated - Construction Major Delays',
    LOAN_UPDATED_CONSTRUCTION_COMPLETE = 'Loan Updated - Construction Complete',
    LOAN_OVERALL_STATUS_WATCH_LIST = 'Loan Updated - Overall status - Watch List',
    LOAN_OVERALL_STATUS_PENDING_FORECLOSURE = 'Loan Updated - Overall status - Pending Foreclosure',
    LOAN_OVERALL_STATUS_ACTIVE_BANKRUPTCY = 'Loan Updated - Overall status - Active Bankruptcy',
    PPM_GENERATED = 'PPM - GENERATED',
    DOCUMENT_UPLOADED = 'Document Upload',
    LOAN_DOCUMENT_UPLOADED = 'Loan Document Upload',
    PAYOFF_REQUEST_SIGNED = 'Payoff Request Signed',
    FUNDING_DASHBOARD_APPROVED = 'Dashboard Request - Approved',
    FUNDING_DASHBOARD_DENIED = 'Dashboard Request - Denied',
    BPA_DRAFT = 'Borrower Project Application - Drafted',
    BPA_AWAITING_SIGNATURE = 'Borrower Project Application - Awaiting Signature',
    BPA_SUBMITTED = 'Borrower Project Application - Signed - Awaiting Approval',
    BPA_DENIED = 'Borrower Project Application - Denied',
    BPA_APPROVED = 'Borrower Project Application - Approved',
    USER_REGISTERED = 'User Registered a New Account',
    USER_INVITATION = 'User Invited as a New Account',
    SCHEDULED_LOAN_MATURITY_BORROWER = 'Loan Maturity - Borrower',
    SCHEDULED_LOAN_MATURITY_INVESTOR = 'Loan Maturity - Investor',
    SCHEDULED_INSURANCE_EXPIRATION = 'Insurance Expiration',
    SCHEDULED_LOAN_GL_EXPIRATION = 'Loan Expiration - GL',
    SCHEDULED_LOAN_BR_EXPIRATION = 'Loan Expiration - BR',
    SCHEDULED_OPEN_TASK_REMINDER_BORROWER = 'Open Task Reminder',
    SCHEDULED_ACH_RECURRING_LP_REMINDER = 'ACH - Scheduled Loan Payment Reminder',
    SCHEDULED_ACH_RECURRING_FUND_REMINDER = 'ACH - Scheduled Fund Reminder',
    AMBASSADOR_REFERRAL_ADDED = 'Referral Status - New Referral Added',
    CHAT_USER = 'Chat - New Chat for User',
    CHAT_ADMIN = 'Chat - New Chat for Admin',
    CHAT_ADMIN_REPLY = 'Chat - New Chat Reply by Admin',
    TASK_USER = 'Task - New Borrower Task updates to User',
    FUND_TASK_USER = 'Task - New Fund investor Task updates to User',
    LPA_TASK_USER = 'Task - New LPA Investor Task updates to User',
    COMMON_TASK_USER = 'Task - New Task updates to User',
    TASK_ADMIN = 'Task - New Borrower Task updates to Admin',
    FUND_TASK_ADMIN = 'Task - New Fund investor Task updates to Admin',
    LPA_TASK_ADMIN = 'Task - New LPA Investor Task updates to Admin',
    COMMON_TASK_ADMIN = 'Task - New Task updates to Admin',
    REFERRAL_EMAIL_SENT = 'Referral Status - Email Sent',
    REFERRAL_EMAIL_OPENED = 'Referral Status - Email Opened',
    REFERRAL_REGISTERED_IN_PORTAL = 'Referral Status - Registered in the Portal',
    REFERRAL_QUALIFIED_NO_ZOOM_SCHEDULED = 'Referral Status - Qualified, No Zoom Scheduled',
    REFERRAL_QUALIFIED_ZOOM_SCHEDULED = 'Referral Status - Qualified, Zoom Scheduled',
    REFERRAL_REFERRAL_FEE_EARNED = 'Referral Status - Referral Fee Earned (Zoom Complete)',
    REFERRAL_REFERRAL_FEE_PAID = 'Referral Status - Referral Fee Paid',
    REFERRAL_NOT_QUALIFIED_ALREADY_IN_SYSTEM = 'Referral Status - Not Qualified, Already in System',
    REFERRAL_NOT_QUALIFIED_QUESTIONNAIRE = 'Referral Status - Not Qualified, Questionnaire',
    FORGOT_USERNAME_EMAIL = 'Forgot Username - Forgot Username Email For Users',
    RESET_PASSWORD = 'Reset Password - Reset Password',
    ONE_NOTARY_SESSION_INVITE_SENT = 'One Notary Session - Invite Sent',
    ONE_NOTARY_SESSION_IDENTITY_REQUIRED = 'One Notary Session - Identity Required',
    ONE_NOTARY_SESSION_COMPLETED = 'One Notary Session - Completed',
    ONE_NOTARY_SESSION_CANCELED = 'One Notary Session - Canceled',
    FEEDBACK_SUBMITTED = 'Feedback Submitted',
}

export enum EntityCategoryEnum {
    TITLE_OFFICE = 'Title Office',
    TITLE_INSURER = 'Title Insurer',
}

export enum EntityTypeEnum {
    LLC = 'Limited Liability Company',
    TRUST = 'Trust',
    CORPORATION = 'Corporation',
    LIMITED_PARTNERSHIP = 'Limited Partnership',
    EMPLOYEE_BENEFIT_PLAN = 'Employee Benefit Plan',
    GENERAL_PARTNERSHIP = 'General Partnership',
}

export enum ErrorTypeEnum {
    INVALID_REQUEST = 'INVALID_REQUEST',
    INVALID_RESULT = 'INVALID_RESULT',
    INVALID_INPUT = 'INVALID_INPUT',
    INSTITUTION_ERROR = 'INSTITUTION_ERROR',
    RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
    API_ERROR = 'API_ERROR',
    ITEM_ERROR = 'ITEM_ERROR',
    ASSET_REPORT_ERROR = 'ASSET_REPORT_ERROR',
    RECAPTCHA_ERROR = 'RECAPTCHA_ERROR',
    OAUTH_ERROR = 'OAUTH_ERROR',
    PAYMENT_ERROR = 'PAYMENT_ERROR',
    BANK_TRANSFER_ERROR = 'BANK_TRANSFER_ERROR',
}

export enum EsignStatusEnum {
    SIGNED = 'SIGNED',
}

export enum EsignTypeEnum {
    SIGNATURE = 'SIGNATURE',
    ENVELOPE = 'ENVELOPE',
}

export enum ExternalOauthProviderEnum {
    MICROSOFT_TEAMS = 'Microsoft Teams',
}

export enum FormOfOwnershipEnum {
    INDIVIDUAL_OWNERSHIP = 'Individual Ownership',
    COMMUNITY_PROPERTY = 'Community Property',
    JOINT_TENANTS = 'Joint Tenants with Rights of Survivorship',
    TENANTS_IN_COMMON = 'Tenants in Common',
    GENERAL_PARTNERSHIP = 'General Partnership',
    LIMITED_PARTNERSHIP = 'Limited Partnership',
    LLC = 'LLC',
    CORPORATION = 'Corporation',
    TRUST = 'Trust',
    EMPLOYEE_BENEFIT_PLAN = 'Employee Benefit Plan',
}

export enum FormStatusEnum {
    INCOMPLETE = 'INCOMPLETE',
    COMPLETE = 'COMPLETE',
}

export enum FormTypeEnum {
    BORROWER_APPLICATION = 'BORROWER_APPLICATION',
}

export enum GlobalSearchEntityTypeEnum {
    CONTACT = 'Contacts',
    ENTITY = 'Entities',
    LOAN = 'Loans',
    FUND = 'Fund Accounts',
    PROPERTY = 'Properties',
    LPA = 'Loan Participations',
    ACH = 'ACH Transfers',
    BPA = 'Borrower Applications',
    AR = 'Ambassador Program',
    TASK = 'Tasks',
    EMAIL = 'Email Log',
    RS = 'Recent Search',
}

export enum HoldingsCodeEnum {
    DEFAULT_UPDATE = 'DEFAULT_UPDATE',
}

export enum HomesteadOwnershipEnum {
    OWN = 'Own',
    RENT = 'Rent',
}

export enum IncomeCodeEnum {
    INCOME_VERIFICATION = 'INCOME_VERIFICATION',
}

export enum InsuranceCoverageStatusEnum {
    COVERED = 'Covered - Active within start and expiration dates, except within 30 days of expiration',
    EXPIRING = 'Expiring - Coverage is within 30 days of expiration',
    NOT_COVERED = "Uninsured - Not covered as it's outside the start and expiration dates",
}

export enum InsuranceCoverageTypeEnum {
    BUILDERS_RISK_INSURANCE = "Builder's Risk Insurance - Covers buildings under construction",
    GENERAL_LIABILITY_INSURANCE = 'General Liability Insurance - Protects against claims of negligence or harm',
    FLOOD_INSURANCE = 'Flood Insurance - Covers damage caused by floods',
    PROPERTY_INSURANCE = 'Property Insurance - Covers damage to physical property',
    EARTHQUAKE_INSURANCE = 'Earthquake Insurance - Covers damage caused by earthquakes',
    FIRE_INSURANCE = 'Fire Insurance - Covers damage caused by fire',
    MORTGAGE_INSURANCE = 'Mortgage Insurance - Protects the lender if the borrower defaults on the loan',
    COMMERCIAL_INSURANCE = 'Commercial Insurance - Covers business-related risks',
    UMBRELLA_INSURANCE = 'Umbrella Insurance - Provides additional liability coverage beyond other policies',
    TITLE_INSURANCE = 'Title Insurance - Protects against issues with property ownership',
    OTHER = 'Other - Insurance not covered by other options',
}

export enum InsuranceDocumentTypeEnum {
    REQUESTED = 'Requested - Indicates that the insurance documentation is expected, but has not yet been received.',
    BINDER = 'Binder - A temporary document that provides proof of insurance coverage until the formal policy is issued.',
    CERTIFICATE_OF_INSURANCE = 'Certificate of Insurance - A document that provides a summary of the insurance coverage, issued after the binder and serving as the final proof of insurance.',
}

export enum InsuranceExpirationTypeEnum {
    COVERAGE_EXPIRES = 'Coverage Expires',
    DO_NOT_EXPIRE = 'Do Not Expire',
}

export enum InsuranceStatusEnum {
    UNMANAGED = 'Unmanaged',
    COVERED = 'Covered',
    EXPIRING_UNDER_30 = 'Expiring In Under 30 Days',
    EXPIRED = 'Expired',
}

export enum InvestmentAdditionalTermsEnum {
    NONE = 'NONE',
    RECURRING_SUBSCRIPTION = 'Recurring Subscription until Cancelled',
    CLASS_A_CONVERT_TO_CLASS_B = 'This Class A investment will convert to Class B on the 1st day of the next quarter',
    EFFECTIVE_ON_THE_FIRST = 'This investment is effective as of the 1st day of this quarter',
    FUNDED_FROM_ROLLOVER = 'This investment is funded from a rollover from a previous investment',
    FIVE_OH_SIX_INVESTMENT_TERMS = '506 Investment Group Terms',
    OTHER = 'Other',
}

export enum InvestmentClassEnum {
    CLASS_A = 'Hornet Capital - Class A',
    CLASS_B = 'Hornet Capital - Class B',
    CLASS_C = 'Hornet Capital - Class C',
    CLASS_D = 'Hornet Debt Fund - Class D',
}

export enum InvestmentLineItemEnum {
    NEW_INVESTMENT = 'New Investment',
    DISTRIBUTION = 'Distribution',
    CASH_DISTRIBUTED = 'Cash Distributed',
    TRANSFER = 'Transfer',
    REDEMPTION = 'Redemption',
}

export enum InvestmentStatusEnum {
    SUBMITTED = '1. Submitted',
    PROCESSING = '2. Processing',
    AWAITING_SIGNATURES = '3. Awaiting Signatures',
    AWAITING_FUNDING = '4. Awaiting Funding',
    SCHEDULED_RECURRING = '5. Scheduled Recurring',
    FUNDED = '6. Funded',
}

export enum InvestmentsTransactionsCodeEnum {
    DEFAULT_UPDATE = 'DEFAULT_UPDATE',
}

export enum ItemCodeEnum {
    ERROR = 'ERROR',
    PENDING_EXPIRATION = 'PENDING_EXPIRATION',
    USER_PERMISSION_REVOKED = 'USER_PERMISSION_REVOKED',
    WEBHOOK_UPDATE_ACKNOWLEDGED = 'WEBHOOK_UPDATE_ACKNOWLEDGED',
}

export enum LiabilitiesCodeEnum {
    DEFAULT_UPDATE = 'DEFAULT_UPDATE',
}

export enum LineItemStatusEnum {
    CREATED = 'Created',
    APPROVED = 'Approved',
    DENIED = 'Denied',
}

export enum LoanExitEnum {
    PAID_OFF_REFINANCED = 'Paid Off - Refinanced External',
    PAID_OFF_REFINANCED_INTERNAL = 'Paid Off - Refinanced Internal',
    PAID_OFF_PROPERTY_SOLD = 'Paid Off - Property Sold',
    PAID_OFF_NOTE_SOLD = 'Paid Off - Note Sold',
    PAID_OFF_CASH = 'Paid Off - Cash',
    PAID_OFF_FORECLOSURE_SALE = 'Paid Off - Foreclosure Sale',
    PAID_OFF_BANKRUPTCY = 'Paid Off - Bankruptcy',
    RIO_FORECLOSURE = 'REO - Foreclosure',
    REO_DEED_IN_LIEU = 'REO - Deed in Lieu',
}

export enum LoanStageEnum {
    APP_RECEIVED = '1. Application Received',
    GENERATING_TERMS = '2. Generating Terms',
    LOI_AWAITING_SIGNATURE = '3. LOI - Awaiting Signature',
    PROCESSING = '4. Processing',
    UNDERWRITING = '5. Underwriting',
    READY_TO_CLOSE = '6. Ready to Close',
    FUNDED = '7. Funded',
    ACTIVE = '8. Active',
    CLOSED = '9. Closed',
    APPLICATION_DENIED = 'Application - Denied',
}

export enum LoanTypeEnum {
    PURCHASE = 'Purchase',
    REFINANCE = 'Refinance',
    PURCHASE_CONSTRUCTION = 'Purchase + Construction',
    REFINANCE_CASH_OUT = 'Refinance with Cash Out',
}

export enum LogMessageTypeEnum {
    FILE_UPLOAD = 'File Upload',
    ENDED_CHAT = 'Ended Chat',
    STARTED_CHAT = 'Started Chat',
    E_SIGNATURE = 'E-Signature',
    RESOLVED = 'Resolved',
    SUBMITTED = 'Submitted',
    TASK_ACTIVE = 'Task Active',
    TASK_PENDING = 'Task Pending',
    TASK_COMPLETE = 'Task Complete',
    TASK_HIDDEN = 'Task Hidden',
}

export enum MainFolderEnum {
    LOAN = 'LOAN',
    CONTACT = 'CONTACT',
    COMPANY = 'COMPANY',
    FINANCIAL_INFORMATION = 'FINANCIAL_INFORMATION',
    EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
    PROPERTY = 'PROPERTY',
    BPA = 'BPA',
    SIGNED_NOTARY_DOCUMENT = 'SIGNED_NOTARY_DOCUMENT',
    INSURANCE = 'INSURANCE',
    UNKNOWN = 'UNKNOWN',
}

export enum MaritalStatusEnum {
    SINGLE = 'Single',
    MARRIED = 'Married',
    DIVORCED = 'Divorced',
    SEPARATED = 'Separated',
    WIDOWED = 'Widowed',
    COMMON_LAW_MARRIED = 'Common-Law Married',
    NO_ANSWER = 'No Answer',
}

export enum MessageCategoryEnum {
    USER_DASHBOARD_HERO_TITLE = 'Title for the hero unit that is on the users dashboard',
    USER_DASHBOARD_HERO_CONTENT = 'Content for the hero unit that is on the users dashboard',
    BORROWER_DASHBOARD_HERO_TITLE = 'Title for the hero unit that is on the BORROWER dashboard',
    BORROWER_DASHBOARD_HERO_CONTENT = 'Content for the hero unit that is on the BORROWER dashboard',
    CONTACT_US_DROPDOWN = 'Formatted content for the dropdown on the contact us page',
    REFERRAL_WELCOME_MESSAGE = 'Message to show users who enter the portal as a referral',
    AMBASSADOR_PROGRAM_WELCOME_MESSAGE = 'Message that shows to users before they have signed up for the ambassador program. Magic tags: {referralFeeAmount} {maxFeesPerYear} {minDaysBetweenPayments}',
    EDIT_SAGE_MODAL = 'Edit Sage Modal',
    EDIT_WIRE_INSTRUCTIONS_MODAL = 'Edit Wire Instructions Modal',
    EMAIL_NOTIFICATION_FOOTER_MESSAGE = 'Message that shows to users in email footer.',
    MOBILE_HERO_CARD_CONTENT = 'Content for Mobile Hero Card',
    MOBILE_HERO_CARD_TITLE = 'Title for Mobile Hero Card',
}

export enum MilitaryBranchEnum {
    ARMY = 'Army',
    AIR_FORCE = 'Air Force',
    COAST_GUARD = 'Coast Guard',
    MARINE_CORPS = 'Marine Corps',
    NATIONAL_GUARD_ARMY = 'National Guard (Army)',
    NATIONAL_GUARD_AIR_FORCE = 'National Guard (Air Force)',
    NAVY = 'Navy',
    INTERNATIONAL_SERVICE_BRANCH = 'International Service Branch',
    SPACE_FORCE = 'Space Force',
    UNKNOWN = 'Prefer not to Answer',
}

export enum MilitaryStatusEnum {
    ACTIVE = 'Active Duty',
    ACTIVE_RESERVES = 'Active Reserves',
    NATIONAL_GUARD = 'National Guard',
    VETERAN_RETIRED = 'Veteran (Retired)',
    VETERAN_NOT_RETIRED = 'Veteran (Not Retired)',
    NON_MILITARY = 'Non-Military',
    UNKNOWN = 'Prefer not to Answer',
}

export enum MsTeamsCompanyEnum {
    HORNET_CAPITAL = 'Hornet Management',
}

export enum NetworkEnum {
    ACH = 'ach',
    SAME_DAY_ACH = 'same-day-ach',
}

export enum NotarySessionStatusEnum {
    INVITE_SENT = 'One Notary Status - Invite Sent',
    IDENTITY_REQUIRED = 'One Notary Status - Identity Required',
    COMPLETED = 'One Notary Status - Completed',
    CANCELED = 'One Notary Status - Canceled',
}

export enum NotificationMethodEnum {
    SMS = 'SMS',
    CALL = 'CALL',
    EMAIL = 'EMAIL',
    NONE = 'NONE',
}

export enum OneNotaryDocumentTypeEnum {
    ORIGINAL = 'Original',
    SIGNED = 'Signed',
}

export enum OneNotaryRoleEnum {
    PRIMARY_SIGNER = 'Primary Signer',
    SIGNER = 'Signer',
    WITNESS = 'Witness',
}

export enum OneNotarySessionStatusEnum {
    CREATED = 'Created',
    CREATE_EXPIRED = 'Create expired',
    INVITE_SENT = 'Invite sent',
    INVITE_EXPIRED = 'Invite expired',
    DRAFT = 'Draft',
    DRAFT_EXPIRED = 'Draft expired',
    IDENTITY_CHECK = 'Identity check',
    IDENTITY_VERIFICATION_FAILED = 'Identity verification failed',
    IDENTITY_LOCKED = 'Identity locked',
    IDENTITY_REQUIRED = 'Identity required',
    IDENTITY_EXPIRED = 'Identity expired',
    ID_VERIFICATION_STARTED = 'ID verification started',
    ID_VERIFICATION_FAILED = 'ID verification failed',
    PAYMENTS = 'Payments',
    PAYMENTS_EXPIRED = 'Payments expired',
    PENDING = 'Pending',
    PENDING_EXPIRED = 'Pending expired',
    READY_TO_START = 'Ready to start',
    READY_TO_START_EXPIRED = 'Ready to start expired',
    SESSION_STARTED = 'Session started',
    SESSION_TERMINATED = 'Session terminated',
    PROCESSING = 'Processing',
    PROCESSING_DOCUMENT_FAILED = 'Processing document failed',
    PROCESSING_VIDEO_FAILED = 'Processing video failed',
    PROCESSING_TERMINATED = 'Processing terminated',
    COMPLETING = 'Completing',
    COMPLETED_PAYMENT_FAILED = 'Completed payment failed',
    COMPLETED_DOCUMENT_DELIVERY_FAILED = 'Completed document delivery failed',
    COMPLETED_SUCCESSFULLY = 'Completed successfully',
    CANCELED = 'Canceled',
    ADDITIONAL_INFORMATION_REQUIRED = 'Addition information required',
}

export enum OneNotaryWebhookEnum {
    IDENTITY_REQUIRED = 'session.status.updated_identity_required',
    COMPLETED = 'session.status.updated_completed_successfully',
    INVITE_SENT = 'session.status.updated_invite_sent',
    CANCELED = 'session.status.updated_canceled',
}

export enum OverallStatusEnum {
    ON_TRACK = 'On Track',
    WATCH_LIST = 'Watch List',
    PENDING_FORECLOSURE = 'Pending Foreclosure',
    ACTIVE_BANKRUPTCY = 'Active Bankruptcy',
}

export enum PaidViaEnum {
    ONLINE = 'Online',
    PAY_LINK = 'Pay Link',
}

export enum PaymentFrequencyEnum {
    MONTHLY = 'Monthly',
    QUARTERLY = 'Quarterly',
    YEARLY = 'Yearly',
    DEFERRED = 'Deferred',
}

export enum PaymentInitiationCodeEnum {
    PAYMENT_STATUS_UPDATE = 'PAYMENT_STATUS_UPDATE',
}

export enum PaymentMethodEnum {
    CREDIT_CARD = 'Credit Card',
    ACH = 'ACH',
    WIRE = 'Wire',
}

export enum PaymentStatusEnum {
    PERFORMING = 'Performing',
    NOT_PERFORMING = 'Not Performing',
}

export enum PaymentTypeEnum {
    STANDARD = 'Standard',
    RECURRING = 'Recurring',
    LINK_PAYMENT = 'Link Payment',
}

export enum PayoffRequestReasonEnum {
    SALE = 'Sale',
    REFINANCE = 'Refinance',
    CASH_PAYOFF = 'Cash Payoff',
    PARTIAL_PAYDOWN = 'Partial Paydown',
    GENERAL_INQUIRY = 'General Inquiry',
    OTHER = 'Other',
}

export enum PropertyAcquisitionTypeEnum {
    PURCHASE = 'Purchase',
    FORECLOSURE = 'Foreclosure',
    DEED_IN_LIEU_OF_FORECLOSURE = 'Deed in Lieu of Foreclosure',
    TAX_SALE = 'Tax Sale',
    EXCHANGE = 'Exchange',
    INHERITANCE = 'Inheritance',
    GIFT = 'Gift',
    ADVERSE_POSSESSION = 'Adverse Possession',
    LEASE_TO_OWN = 'Lease to Own',
    CROWDFUNDING = 'Crowdfunding',
}

export enum PropertyConditionEnum {
    EXCELLENT = 'Excellent',
    GOOD = 'Good',
    FAIR = 'Fair',
    POOR = 'Poor',
}

export enum PropertyOwnerTypeEnum {
    INDIVIDUAL = 'Individual',
    ENTITY = 'Entity',
}

export enum PropertyTypeEnum {
    SFR = 'Single Family Residential',
    DUPLEX = 'Duplex',
    TRIPLEX = 'Triplex',
    QUADPLEX = 'Quadplex',
    MF_5_PLUS = 'Multi-Family',
    TOWNHOME = 'Townhome',
    CONDO = 'Condominium',
    LAND = 'Land',
    COMMERCIAL = 'Commercial',
    OTHER_TYPE = 'Other',
}

export enum QuickbooksCompanyEnum {
    HORNET_MANAGEMENT = 'Hornet Management',
    HORNET_CAPITAL = 'Hornet Capital',
}

export enum RecurringPaymentFrequencyEnum {
    ONE_TIME = 'One Time',
    WEEKLY = 'Weekly',
    BI_WEEKLY = 'Bi-Weekly',
    MONTHLY = 'Monthly',
}

export enum ReferralStatusEnum {
    PROCESSING = 'Processing',
    EMAIL_SENT = 'Email Sent',
    EMAIL_OPENED = 'Email Opened',
    REGISTERED_IN_PORTAL = 'Registered in the Portal',
    QUALIFIED_NO_ZOOM_SCHEDULED = 'Qualified, No Zoom Scheduled',
    QUALIFIED_ZOOM_SCHEDULED = 'Qualified, Zoom Scheduled',
    REFERRAL_FEE_EARNED = 'Referral Fee Earned (Zoom Complete)',
    REFERRAL_FEE_PAID = 'Referral Fee Paid',
    NOT_QUALIFIED_ALREADY_IN_SYSTEM = 'Not Qualified, Already in System',
    NOT_QUALIFIED_QUESTIONNAIRE = 'Not Qualified, Questionnaire',
    NO_RESPONSE = 'No Response',
}

export enum ReportTypeEnum {
    OWNER_DASHBOARD = 'ADMIN - Owner Dashboard',
    INVESTOR_DASHBOARD = 'USER - Investor Dashboard',
    PRE_INVESTOR_DASHBOARD = 'USER - Pre-Investor Dashboard',
    LOAN_DASHBOARD = 'USER - Loan Dashboard',
    LPA_REPORT = 'USER - LPA Report',
}

export enum RoleForTeamsChannelEnum {
    INVESTOR = 'Investor',
    BORROWER = 'Borrower',
}

export enum SMSConversationTypeEnum {
    INVESTOR_DASHBOARD = 'Investor Dashboard',
    PRE_INVESTOR_DASHBOARD = 'Pre-Investor Dashboard',
    LOAN_DASHBOARD = 'Loan Dashboard',
}

export enum SettingsApplicationConfigTypeEnum {
    JSON = 'Json',
    PLAIN = 'Plain',
}

export enum SexEnum {
    MALE = 'Male',
    FEMALE = 'Female',
    NO_ANSWER = 'No Answer',
}

export enum SmsStatusEnum {
    queued = 'Queued',
    sent = 'Sent',
    delivered = 'Delivered',
    undelivered = 'Undelivered',
    failed = 'Failed',
}

export enum StatusEnum {
    PENDING = '1. Submitted',
    COMPLETE = '2. Processing',
    APPROVED = '3. Awaiting Signatures',
    DENIED = '4. Awaiting Funding',
    FUNDED = '5. Funded',
    PAID_OFF = '6. Paid Off',
}

export enum SubscriptionAgreementStatusEnum {
    CLOSED = 'Closed',
    OPEN = 'Open',
}

export enum SystemConfigEnum {
    TIME_ZONE_KEY = 'hornet.app.timeZone',
    NOTIFICATION_PAUSE_KEY = 'notification.paused',
}

export enum TaskRequirementTypeEnum {
    REQUIRED = 'Required',
    IF_APPLICABLE = 'If Applicable',
    HIDDEN = 'Hidden',
}

export enum TaskStatusEnum {
    ACTIVE = 'Requires Action',
    PENDING = 'Under Review',
    COMPLETE = 'Complete',
    HIDDEN = 'Hidden',
}

export enum TaskTargetEnum {
    BORROWER = 'Borrower Task',
    INVESTOR = 'Investor Task',
}

export enum TaskTriggerEnum {
    NEW_LOAN = 'Creation of a New Loan',
    NEW_USER = 'User registers',
    LETTER_OF_INTENT_E_SIGN = 'Letter-of-Intent E-Sign Request',
    PAYOFF_REQUEST_E_SIGN = 'Payoff Request E-Sign Request',
    FUND_SUBSCRIPTION_E_SIGN = 'Fund Subscriptions E-Sign Request',
    NEW_LOAN_APPLICATION_SUBMIT = 'Loan Application Submitted',
    NEW_NOTARY_DOCUMENT = 'New Document Notary Request',
    LOAN_PARTICIPANT_AGREEMENT_E_SIGN = 'Loan Participant Agreement E-Sign Request',
}

export enum TeamsGroupByEnum {
    LOAN = 'Loan',
    ENTITY = 'Entity',
    CONTACT = 'Contact',
}

export enum TeamsTypeEnum {
    CHAT = 'Chat',
    TASK = 'Task',
}

export enum ThirdPartyNameEnum {
    SAGE = 'Sage',
}

export enum TitleEndorsementStatusEnum {
    REQUESTED = 'Requested',
    APPROVED = 'Approved',
    NOT_AVAILABLE = 'Not Available',
}

export enum TransactionsCodeEnum {
    INITIAL_UPDATE = 'INITIAL_UPDATE',
    HISTORICAL_UPDATE = 'HISTORICAL_UPDATE',
    DEFAULT_UPDATE = 'DEFAULT_UPDATE',
    TRANSACTIONS_REMOVED = 'TRANSACTIONS_REMOVED',
}

export enum TriggerTypeEnum {
    SCHEDULE = 'Scheduled',
    ON_ACTION = 'On Action',
}

export enum TwoFaTypeEnum {
    OFF = 'Turn off',
    SMS = 'Text SMS',
    APP_PUSH_NOTIFICATION = 'Mobile Application',
}

export enum UserInterfaceEnum {
    ADMIN = 'Admin',
    USER = 'User',
    AUTH = 'Authentication',
}

export enum VoiceCallActionEnum {
    START_CALL = 'Start Call',
    MUTE = 'Mute',
    UNMUTE = 'Unmute',
    END_CALL = 'End Call',
    STOP_RECORDING = 'Stop Recording',
    START_RECORDING = 'Start Recording',
    PAUSE_RECORDING = 'Pause Recording',
    RESUME_RECORDING = 'Pause Recording',
}

export enum WebhookTypeEnum {
    ITEM = 'ITEM',
    TRANSACTIONS = 'TRANSACTIONS',
    AUTH = 'AUTH',
    ASSETS = 'ASSETS',
    HOLDINGS = 'HOLDINGS',
    INVESTMENTS_TRANSACTIONS = 'INVESTMENTS_TRANSACTIONS',
    LIABILITIES = 'LIABILITIES',
    PAYMENT_INITIATION = 'PAYMENT_INITIATION',
    BANK_TRANSFERS = 'BANK_TRANSFERS',
    INCOME = 'INCOME',
    DEPOSIT_SWITCH = 'DEPOSIT_SWITCH',
}
