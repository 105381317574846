import React, {ChangeEvent, KeyboardEvent, useEffect, useRef, useState} from 'react';
import {Chat, ChatMessage} from '@interfaces/task';
import {FaCircleNotch} from 'react-icons/fa';
import style from './style.module.scss';

import {sendAdminMessage} from '@hornet-api/task/sendAdminMessage';
import {typingDurationTime, uploadChatFiles} from "@components/NotificationSidebar/utils";
import notifyAdminTyping from "@hornet-api/chat/notifyUserTyping";
import UsersTypingList from "@components/NotificationSidebar/UsersTypingList";
import isAdminState from "@state/globalState/isAdminState";
import {CgAttachment} from "react-icons/cg";
import {notifyUserTyping, sendUserMessage} from "@hornet-api/task/user";

type Props = {
  chat: Chat;
  messageToEdit?: ChatMessage | null;
  onSubmitted?: () => void | Promise<void>;
  isAnnouncement?: string | null;
}
const MessageInput = React.forwardRef(
  ({
     chat,
     onSubmitted,
     messageToEdit,
     isAnnouncement,
   }: Props, ref: React.ForwardedRef<HTMLTextAreaElement>) => {
    const [isLoading, setIsLoading] = useState(false)
    const [text, setText] = useState('');
    const bottomRef = useRef<HTMLDivElement>(null);
    const lastSentRef = useRef(0);
    const typingTimeoutRef = useRef<number | null>(null);
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const isAdmin = isAdminState.useValue();

    useEffect(() => {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [chat]);

    useEffect(() => {
      setText(messageToEdit?.content || '')
    }, [messageToEdit])

    const sendTypingNotification = () => {
      const currentTime = Date.now();
      const timeSinceLastSend = currentTime - lastSentRef.current;

      // Only send the notification if 10 seconds have passed since the last one or if it's the first time typing
      if (timeSinceLastSend > typingDurationTime && text && chat.id) {
        lastSentRef.current = currentTime; // Update the last sent time
        const notifyTypingApi = isAdmin ? notifyAdminTyping : notifyUserTyping
        notifyTypingApi(chat.id).catch(() => {
          lastSentRef.current = 0;
        });
      }
    };

    useEffect(() => {
      if (text && chat && !isAnnouncement) {
        // Clear any existing timeout before setting a new one
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current);
        }

        // Send an immediate notification when typing starts
        let timeOutIn = typingDurationTime;
        if (lastSentRef.current === 0) {
          sendTypingNotification();
        } else {
          const currentTime = Date.now();
          const timeSinceLastSend = currentTime - lastSentRef.current
          timeOutIn = typingDurationTime - timeSinceLastSend
        }

        // Set a timeout for 10 seconds to send the next notification if they keep typing
        typingTimeoutRef.current = window.setTimeout(() => {
          sendTypingNotification();
        }, timeOutIn) as unknown as number; // Explicit cast to number
      } else if (typingTimeoutRef.current) {
        // If typing stops, clear the timeout
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = null; // Reset timeout reference
        lastSentRef.current = 0; // Reset the last sent time
      }

      return () => {
        // Cleanup on unmount or before re-running useEffect
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current);
        }
      };
    }, [text, chat]);


    const onSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      const trimText = text.trim();
      if (isLoading || !trimText) return;
      setIsLoading(true);
      const sendMessageApi = isAdmin ? sendAdminMessage : sendUserMessage
      sendMessageApi({chat: chat.id, id: messageToEdit?.id, content: trimText})
        .then(() => setText(""))
        .then(onSubmitted)
        .then(() => setIsLoading(false));
    };

    const handleChatFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        const files = Array.from(event.target.files);
        await uploadChatFiles(files, chat.id.toString());
        onSubmitted?.();
        event.target.value = '';
      }
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        return onSubmit(event);
      }
    }

    return (<>
      <div className={`border-top ${style.messageInput}`}>
        <UsersTypingList chatId={chat.id}/>
        <form onSubmit={onSubmit}>
        <textarea
          rows={3}
          value={text}
          className="form-control"
          placeholder='Write message here..'
          onChange={(e) => setText(e.target.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}$!^=+]/gu, ''))}
          ref={ref}
          onKeyDown={handleKeyDown}
        />
          <div className={style.btnBox} ref={bottomRef}>
            <button
              type="submit"
              className='btn btn-primary btn-sm'
              disabled={isLoading}
            >
              {isLoading ? <FaCircleNotch className='spin'/> : 'Send Message'}
            </button>
            {isAdmin && !isAnnouncement && (
              <>
                <CgAttachment
                  onClick={() => {
                    hiddenFileInput?.current?.click();
                  }}
                  className={'m-2'}
                  style={{cursor: 'pointer'}}
                />
                <input
                  type="file"
                  id="chat-documents"
                  ref={hiddenFileInput}
                  className={'d-none'}
                  onChange={handleChatFileUpload}
                  multiple
                />
              </>
            )}
          </div>
        </form>
      </div>
    </>);
  })

export default MessageInput;