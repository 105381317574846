import axios from 'axios';
import {getBaseUri} from '@common/baseUri';
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";

export type RegistrationData = {
  firstName: string,
  lastName: string,
  username: string,
  email: string,
  password: string,
  password2: string
  roles: SelectOptionProps | SelectOptionProps[] | null | string,

}

const registerUser = async (data:RegistrationData) => {
	const url = `${getBaseUri()}api/public/register`;
	const response = await axios.post(url, data);
	return response.data;
};

export default registerUser;
