import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import useSignInUser from '@views/LoginView/LoginDisplayView/useSignInUser';
import hornetCapitalLogo from '@assets/images/hornet_capital_logo.svg';
import blueFooterImage from '@assets/images/blue_footer_image.svg';
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {TextBox} from "@components/forms/react-hook-form-bootstrap";
import {Button, Form} from "react-bootstrap";
import {onInvalidHandler} from "@components/forms/react-hook-form-bootstrap/utils";
import activeRoleState from "@state/globalState/activeRoleState";
import useLogoutHandler from "@legacy/views/auth/useLogoutHandler";
import {FaCircleNotch} from "react-icons/fa";

type LoginForm = {
  username: string
  password: string
}
const LoginDisplayView = () => {
  const signInUser = useSignInUser();
  const logoutHandler = useLogoutHandler(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const errormessage = useMemo(() => {
    if (error === null) return null;
    let message = error as string;
    switch (error) {
      case 'MISSING_USERNAME_OR_PASSWORD':
      case 'INVALID_USERNAME_OR_PASSWORD':
        message = 'Invalid username or password';
        break;
      case 'ACCOUNT_EXPIRED':
      case 'PASSWORD_EXPIRED':
      case 'ACCOUNT_DISABLED':
      case 'ACCOUNT_LOCKED':
      case 'BRUTE_FORCE_LOCKOUT':
        message = 'Account is currently locked';
        break;
      case 'EMAIL_VERIFICATION_REQUIRED':
        message = 'Email verification is required, please check your email to verify your account';
        break;
      case 'SESSIONS_EXCEEDED':
        message = 'The maximum number of users has been exceeded';
        break;
      case 'PERMISSION_DENIED':
        message = 'Permission has been denied';
        break;
      case 'OTP_RESEND_LOCKED':
        message = 'Two Factor Authentication resend limit reached. Please contact Hornet.';
        break;
      default:
        message = 'An unknown error has occurred';
        break;
    }
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }, [error]);

  const {
    handleSubmit,
    control,
    reset,
  } = useForm<LoginForm>();

  useEffect(() => {
    activeRoleState.set(null);
    reset({username: '', password: ''});
    logoutHandler();
  }, []);
  const onInvalid: SubmitErrorHandler<LoginForm> = (errs) => {
    onInvalidHandler(errs)
  }
  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    setIsSubmitting(true);
    signInUser(data.username, data.password).catch(oe => {
      if (oe?.message) return setError(oe.message);
      setError(oe);
    }).finally(() => setIsSubmitting(false));
  }
  return (
    <div className="login-container">
      <div className="container">
        <img src={hornetCapitalLogo} alt="Hornet Capital" className="logo"/>
        <Form className="login-form" noValidate onSubmit={handleSubmit(onSubmit, onInvalid)} id="loginForm">
          <h4 className={'text-center'}>Sign In</h4>
          <TextBox
            name={'username'}
            control={control}
            label={'Username'}
            rules={{required: true}}
          />
          <TextBox
            name={'password'}
            control={control}
            label={'Password'}
            type={'password'}
            rules={{required: true}}
          />
          {errormessage}
          <div className="text-center">
            <Button
              type="submit"
              name="Login"
              variant={'warning'}
              className="btn btn-block"
              disabled={isSubmitting}
            >Log In {isSubmitting && <FaCircleNotch className={'spin'}/>}
            </Button>
          </div>
        </Form>
        <div className={'mt-5'}><Link to={'/forgotPassword'}>Forgot Password?</Link></div>
        <div ><Link to={'/forgotUsername'}>Forgot Username?</Link></div>
        <div className={'mt-5'}>Don't have an account? <Link to={'/register'}>Sign up</Link></div>
      </div>
      <div className="footer" style={{backgroundImage: `url( ${blueFooterImage})`}}></div>
    </div>
  );
};

export default LoginDisplayView;
