/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
/* eslint-disable curly */
import {useCallback, useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import accessTokenExpirationSelectorAtom from './accessTokenExpirationSelectorAtom';
import authenticationBlobAtom, {defaultAuthenticationBlob} from './core/authenticationBlobAtom';
import useBearerTokenRef from './useBearerTokenRef';
import hasRoleConflictState from "@state/globalState/hasRoleConflictState";

const FIVE_MINUTES = 300000;
const ONE_MINUTE = 60000;

const useTokenExpirationTimer = (fn, offset = FIVE_MINUTES, interval = ONE_MINUTE) => {
	const { bearerTokenRef } = useBearerTokenRef();
	const expiration = useRecoilValue(accessTokenExpirationSelectorAtom);
	const setAuthenticationBlobAtom = useSetRecoilState(authenticationBlobAtom);
	const hasRoleConflict = hasRoleConflictState.useValue();

	const resetRoleConflictState = useCallback(
		() => {
			if (hasRoleConflict) {
				window.location = window.location.href;
			}
		},
		[hasRoleConflict]
	);

	const raiseFunctionEvent = useCallback(async () => {
		let results = null;
		if (typeof fn === 'function' && bearerTokenRef.current) {
			try {
				results = await Promise.resolve(fn(bearerTokenRef.current)) ?? null;
			} catch (oe) {
				console.error(oe);
			}
		}

		if (results === null) {
			setAuthenticationBlobAtom(defaultAuthenticationBlob());
			resetRoleConflictState();
			return;
		}

		setAuthenticationBlobAtom((state) => {
			if (results.length === 1) {
				if (state.impersonation_user) return {
					...state,
					impersonation_user: results[0],
				};

				return {
					...state,
					active_user: results[0],
				};
			} else if (results.length === 2) {
				return {
					active_user: results[1],
					impersonation_user: results[0],
				};
			}
			return state;
		});
		resetRoleConflictState();

	}, [bearerTokenRef, fn, setAuthenticationBlobAtom, resetRoleConflictState]);

	useEffect(() => {
		let timer = null;
		if (expiration) {
			console.info('Token Monitoring Event starting');
			timer = setInterval(() => {
				// eslint-disable-next-line no-magic-numbers
				const exp = (expiration * 1000) - offset;
				console.group('Token Monitoring Event');
				console.info('The current event time is', new Date());
				console.info('Requesting the next refresh to occur after', new Date(exp));
				if (Date.now() >= exp) {
					console.info('The next refresh time has elapsed');
					//clearInterval(timer);
					//timer = null;
					console.info('Requesting a token refresh');
					raiseFunctionEvent();
				}
				console.groupEnd();
			}, [interval]);
		}
		return () => {
			if (timer) {
				console.info('Token Monitoring Event stopping');
				clearInterval(timer);
			}
		};
	}, [expiration, interval, offset, raiseFunctionEvent]);

	useEffect(() => {
		if (hasRoleConflict) {
			raiseFunctionEvent();
		}
	}, [raiseFunctionEvent, hasRoleConflict]);
	
};

export default useTokenExpirationTimer;
