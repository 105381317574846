import React, {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import profileState from '@state/globalState/profileState';
import style from './style.module.scss';
import {Chat, ChatMessage, Message, Task} from '@interfaces/task';
import isAdminState from "@state/globalState/isAdminState";
import markChatResolved, {MarkResolvedMessage} from '@hornet-api/task/markChatResolved';
import {FaCircleNotch, FaRegEye, FaTrash} from 'react-icons/fa';
import {getLinkUrl} from '@hornet-api/task/attachFile';
import Empty from "@components/NotificationSidebar/components/Empty";
import PushNotificationRequest from "@components/PushNotificationRequest";
import {formatDate} from "@common/basic";
import {DateFormats} from "@interfaces/DateFormats";
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import getUserApiPath from "@hornet-api/getUserApiPath";
import {getAccessToken} from "@common/token";
import {openShowDocumentModal} from "@components/ShowDocumentModal";
import {deleteChatAttachment} from "@components/NotificationSidebar/utils";
import {alertApiErrors} from "@common/errors";
import {useRecoilValue} from "recoil";
import authenticationBlobAtom from "@state/recoil/authentication/core/authenticationBlobAtom";

type Props = {
  messages: Message[],
  onSubmitted?: () => void | Promise<void>,
  isResolved: boolean,
  task?: Task,
  chat?: Chat,
  isAnnouncement?: null | string;
  messageToEdit?: ChatMessage | null;
  onEdit?: (message: ChatMessage | null) => void
  onDelete?: (message: ChatMessage | null) => void
  onDeleteChatAttachment?: () => void
}

const generateDocumentUrl = (isAdmin: boolean, fileId: number, messageId: number, type: 'download' | 'view') => {
  return `${
    isAdmin ? getAdminApiPath() : getUserApiPath()
  }/chat/message/${messageId}/attachment/${fileId}/${type}?access_token=${getAccessToken()}`
}

const getLogMessage = (message: Message, isAdmin: boolean, task?: Task, onDeleteChatAttachment?: () => void) => {
  // log message
  if (message.type === 'LOG') {
    // don't show resolved message to users
    if (!isAdmin && message.logType === 'RESOLVED') {
      return null;
    }

    if (message.logType === 'RESOLVED') {
      return <>{message.from?.name} resolved conversation</>
    }

    if (message.logType === 'FILE_UPLOAD') {
      const attachment = task?.attachments.find(x => Number(message.content) === x.id);

      if (!attachment || !task) {
        return <>
          {message.from?.name} uploaded Unknown File
        </>
      } else {
        const url = getLinkUrl({
          taskId: task.id,
          attachmentId: attachment.id,
          download: true
        })
        return <>
          {message.from?.name} uploaded <a href={url} target="_blank">{attachment.fileName}</a>
        </>
      }
    }

    if (message.logType === 'SUBMITTED') {
      return <>
        {message.from?.name} submitted task
      </>
    }

    return getMessageElement(message.content || '');
  }

  if (message.attachments.length) {
    return (
      <>
        {message.from.name} uploaded
        {message.attachments.map((attachment, index) => (
          <div key={index}>
            <a
              href={generateDocumentUrl(isAdmin, attachment.id, message.id, 'download')}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-1"
            >
              {attachment.fileName}
            </a>
            <span
              role="button"
              className="ml-2"
              onClick={() => {
                openShowDocumentModal({
                  url: generateDocumentUrl(isAdmin, attachment.id, message.id, 'view'),
                  name: attachment.fileName
                });
              }}
            >
            <FaRegEye/>
          </span>
            {isAdmin && (
              <span
                role="button"
                className="ml-2"
                onClick={() => confirm('Are you sure?') && deleteChatAttachment(message.id, attachment.id)
                  .then(() => onDeleteChatAttachment?.())
                  .catch(alertApiErrors)}
              >
                  <FaTrash/>
              </span>
            )
            }
          </div>
        ))}
      </>
    );
  }

  return null;
}

const getMessageElement = (msg: string) => {
  const linkRegex = /\[(.*?)]\((.*?)\)/gm;
  // const match = msg.match(linkRegex);
  const linkMatch = linkRegex.exec(msg);

  const newLines = (x: string) => {
    x = x.replace(/\r\n|\r|\n/g, '\r');
    const xSplit = x.split('\r');
    return xSplit.map((t, i) => {
      return <React.Fragment key={i}>{t}{i < xSplit.length - 1 ? <br/> : null}</React.Fragment>
    });
  }
  if (linkMatch && linkMatch.length === 3) {
    const [orig, text, link] = linkMatch;
    const msgSplit = msg.split(orig);
    return (<>
      {newLines(msgSplit[0])}<a target='_blank' href={link}>{text}</a>{newLines(msgSplit[1])}
    </>);
  }
  // return msg;
  return newLines(msg);
}

const Messages = (
  {
    messages,
    isResolved,
    task,
    chat,
    onSubmitted,
    isAnnouncement,
    messageToEdit,
    onEdit,
    onDelete,
    onDeleteChatAttachment,
  }: Props
) => {
  const [isResolving, setIsResolving] = useState(false);
  const [isOnBottom, setIsOnBottom] = useState(true);
  const profile = profileState.useValue();
  const isAdmin = isAdminState.useValue();
  // This is kept here so that it re-renders the Task Attachment download link on change of the auth token through `getLogMessage()`
  const authenticationBlob = useRecoilValue(authenticationBlobAtom);
  const refMessagesDiv = useRef<HTMLDivElement>(null);
  const messagesDiv = useRef<HTMLDivElement>(null);

  const handleDelete = (message: ChatMessage) => {
    if (confirm("Are you sure you want to delete this message?")) {
      onDelete?.(message);
    }
  };


  setTimeout(() => {
    messagesDiv.current?.scrollIntoView({block: 'end', behavior: 'smooth'});
  }, 200)


  useEffect(() => {
    messagesDiv.current?.scrollIntoView({block: 'end', behavior: 'smooth'});
  }, [messages])

  useEffect(() => {
    if (isOnBottom) {
      setTimeout(() => {
        if (refMessagesDiv.current) {
          const target = refMessagesDiv.current;
          const scrollBottom = target.scrollTop + target.clientHeight;
          target.scrollTo(0, scrollBottom);
        }
      }, 100)
    }
  }, [messages])

  const notificationToggle = useMemo(() => {
    return (
      <div className={style.lastWord} style={{padding: '0px 20px'}}>
        <small>
          To be notified about new messages, enable browser notifications
        </small>
        <PushNotificationRequest className={'btn-sm'}/>
      </div>
    )

  }, [messages, isAdmin, isResolving, chat])

  // show resolve button bar if last word is not from admin
  const lastWord = useMemo(() => {
    let showResolveButton = !isResolved;
    if (!isAdmin && !profile) {
      return null;
    }
    if (isAdmin && showResolveButton) {
      return (
        <div className={style.lastWord}>
          <small>
            Resolve this conversation by responding or clicking below
          </small>
          <button
            className='btn btn-success btn-sm'
            disabled={isResolving}
            onClick={async () => {
              if (isResolving) return;
              let opts: MarkResolvedMessage = {};
              if (task) {
                opts.taskId = task.id;
              } else if (chat) {
                opts.chatId = chat.id;
              } else {
                alert('Not supported');
                return;
              }
              setIsResolving(true);
              await markChatResolved(opts);
              // callback when done
              if (onSubmitted) {
                await onSubmitted();
              }
              setIsResolving(false);
            }}
          >
            {isResolving ? <FaCircleNotch className='spin'/> : 'Resolve Conversation'}
          </button>
        </div>
      );
    } else {
      return null;
    }
  }, [messages, isAdmin, isResolving, chat, authenticationBlob]);

  return (
    <div
      ref={refMessagesDiv}
      className={style.messages}
      onScroll={(e) => {
        const target = e.target as HTMLDivElement;
        const scrollBottom = target.scrollTop + target.clientHeight
        let ob = false;
        if (target.scrollHeight === scrollBottom) {
          ob = true;
        }
        if (isOnBottom !== ob) {
          setIsOnBottom(ob);
        }
      }}
    >
      {
        messages.map((message, index) => {
          const logMessage = getLogMessage(message, isAdmin, task, onDeleteChatAttachment);
          if (logMessage) {
            return (
              <div key={index} className={style.note}>
                <div className={style.noteDate}>
                  {formatDate(message.dateCreated, DateFormats.SEMI_SINGLE_DIGIT)}
                </div>
                <div className={style.noteText}>
                  {logMessage}
                </div>
              </div>
            );
          }
          // normal message
          if (message.type === 'CHAT') {
            if (!message.content) {
              return null;
            }

            const isFromUser = isAdmin && message.from.isAdmin ? true : message.from.id === profile?.id;
            const isThisMessageBeingEdited = messageToEdit?.id === message.id;
            return (
              <div key={index} className={classNames([style.message, isFromUser ? style.isFromUser : ''])}>
                <div className={style.messageName}>
                  {message.from?.name}
                </div>
                <div className={`${style.messageBox} ${isThisMessageBeingEdited ? style.editing : ""}`}>
                  <div className={style.messageDate}>
                    {formatDate(message.dateCreated, DateFormats.SEMI_SINGLE_DIGIT)}
                  </div>
                  {isAdmin && !isAnnouncement && onDelete && (
                    <div onClick={() => !isThisMessageBeingEdited && handleDelete(message)}
                         className={`${style.messageDelete} ${isThisMessageBeingEdited ? style.disabledAction : ""}`}>
                      <i className={`fa fa-trash`}/>
                    </div>
                  )}
                  {isAdmin && (
                    <div
                      onClick={() => onEdit?.(isThisMessageBeingEdited ? null : message)}
                      className={style.messageEdit}
                    >
                      <i className={`fa fa-${isThisMessageBeingEdited ? 'close' : 'edit'}`}/>
                    </div>
                  )}
                  <div className={style.messageContent}>
                    {getMessageElement(message.content)}
                  </div>
                </div>
              </div>
            );
          }
        })
      }
      {lastWord}
      {notificationToggle}
      {
        messages.length === 0 ? <Empty type={'chat'}/> : null
      }
      <div ref={messagesDiv}/>
    </div>
  )
}

export default Messages;